<template>
  <div class="flex-col page">
    <div class="flex-col section_3 space-y-52">
      <span class="text_3">《摄影师申请协议》</span>
      <div>
        <span class="font_1">
          中天图库在此特别提醒您在申请成为注册摄影师或签约摄影师之前，请认真阅读本《摄影师申请协议》（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您不能注册申请成为本网站摄影师。
          我承诺接受以下条款：<br />
        </span>

        <span class="font_1">
          1、您在注册申请时按要求自愿提供准确而详尽的个人资料，并支付一定费用。如个人资料有任何变动，必须及时更新或主动告知；如果您提供的个人资料不准确、不真实、不合法，本站保留结束您使用中天图库各项服务的权利；由于您提供的个人资料不准确、不真实、不合法所造成的损失，完全由您本人承担；
          <br />
        </span>
        <span class="font_1">
          2、您对所提交的照片、视频必须为个人独立创作拍摄，确保自己为提交照片、视频的唯一作者，并对该照片、视频的整体及局部均拥有独立、完整、明确、无争议的著作权；您还应保证所投送的照片、视频不侵犯第三人的包括著作权、肖像权、名誉权、隐私权等在内的合法权益；承诺人像照片均已得到肖像权人同意认可，并提交相关证明。中天图库提醒您关于所提交的照片、视频之一切法律责任均由您本人承担，凡存在违反法律法规以及公序良俗等不符要求的作品以及接到投诉的，中天图库有权在不通知您的情况下取消您在本网站注册摄影师资格，并删除相关照片。
        </span>
        <span class="font_1">
          3、您在系统提示成功提交申请之后，表明您的信息已成功提交中天图库工作后台。但并不意味着您已成功注册成为中天图库摄影师。如因不可抗力导致部分或全部信息遗失，中天图库有权联络您，并要求再次提交完整申请信息。
          <br />
        </span>

        <span class="font_1">
          4、系统将可能不定期的修改本用户协议的有关条款，一旦条款内容产生变动，本站将会在重要页面上提示修改内容。如果不同意本站对条款内容所做的修改，您可以主动联系取消其在本网站注册申请的摄影师资格。如果用户未主动提出，则视为接受服务条款的变动。本站保留随时修改或中断服务而不需对您进行告知的权利。本站行使修改或中断服务的权利，不需对您或第三方负责。
                    <br /></span>
        <span class="font_1">
        5、您或本站可随时根据实际情况中断一项或多项服务。本站不需对任何个人或第三方负责而随时中断服务。您对后来的条款修改有异议，或对本站的服务不满，可以行使如下权利：停止使用本站的服务、通告本站停止对您的服务。
          <br /></span>
        <span class="font_1">
        6、您成为本站注册摄影师之后，您提交的照片、视频通过审核将在本站相关板块进行展示，本站负责宣传推介，在推介宣传等商业活动中，可以使用您提交的照片和视频。本站用户下载照片的费用标准以及本站和您的分配比例，由中天图库进行确定。  <br /></span>
        <span class="font_1">
         7、本协议的订立、执行和解释及争议的解决均应适用相关法律法规。如发生本站服务条款与法律法规相抵触时，则这些条款将完全按法律规定解释，而其它合法条款则依旧保持对用户产生法律效力和影响。本协议的规定是可分割的，如本协议任何规定被裁定为无效或不可执行，该规定可被删除而其余条款应予以执行。<br /></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="css">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.section_3 {
  padding: 1.94rem 1rem 9.38rem 1.47rem;
  background-color: #ffffff;
  overflow-y: auto;
}
.space-y-52 > *:not(:first-child) {
  margin-top: 1.63rem;
}
.text_3 {
  align-self: center;
  color: #333333;
  font-size: 1.06rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 1.06rem;
}
.font_1 {
  font-size: 0.88rem;
  font-family: Adobe Heiti Std;
  line-height: 1.25rem;
  color: #333333;
}
</style>
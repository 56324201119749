<template>
    <div class="flex-col page">
      <div style="background-color: #ffffff ;display: flex;justify-content: flex-end;padding-right: 20px;
    padding-top: 20px;"><van-icon name="cross" size="40px"  @click="  this.$router.go(-1);" /></div>
        <div class="header"></div>
        <div class="flex-col space-y-15 section_3">
            <div class="flex-col">
              <van-uploader :after-read="afterRead" capture="camera" style="align-self: center" >
                <div class="flex-col items-center space-y-15 section_4" v-if="!imgsrc">
                    <img src="../../assets/imgs/icon_carmera.png"
                            class="image_4"
                    />
                    <span class="text_3">拍照搜索</span>
                </div>
                <div class="flex-col items-center space-y-15 " style="max-width: 200px;max-height: 200px"  v-if="imgsrc">
                  <img style="max-width: 200px;max-height: 200px"
                      :src="imgsrc"

                  />

                </div>
              </van-uploader>
                <div class="flex-col space-y-34 group_3">
                    <div class="group_4">
<!--            <span class="text_4">-->
<!--              上传您本人正面照片-->
<!--              <br />-->
<!--            </span>-->
                        <span class="text_5">自拍一张照片，帮您找到在本次活动中最靓丽的您</span>
                    </div>
                    <div class="flex-col items-center button" v-if="!ossimgurl"><span class="text_6">识别查找</span></div>
                  <div class="flex-col items-center button_s" v-if="ossimgurl"><span class="text_6s" @click="godetail">识别查找</span></div>
                </div>
            </div>
            <span class="text_7">照片仅用于您本人的搜索,不会保存</span>
        </div>
    </div>
</template>

<script>
import {generateUrl, seachface, uploadfile} from "@/api/unit";
    import {Toast} from "vant";
    import axios from "axios";

    export default {
        components: {},
        data() {
            return {
              imgsrc:'',
              id:'',
              uploadedimg:'',
              imglist:[],
              ossimgurl:'',
            };
        },
        methods: {

          afterRead(file){
            file.status ='uploading'
            file.message = '上传中...';
            console.log(file)
            this.imgsrc = file.content;
            this.imglist.push(file);
            file.index = this.imglist.length-1;
            var that =this;
            uploadfile().then(function (data) {
              if (data.code == 200) {
                that.uploadossimg(data.data,file)

              } else {
                Toast(data.msg)

              }
            }).catch(function (err) {
              console.log(err);
            })

          },
          godetail(){
            this.seachface(this.ossimgurl);

          },
          seachface(imgurl){
            const toast = Toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
              message: "加载中..."
            });
            var that = this;
            var updata ={
              url:imgurl,
              actId:this.id
            }
            seachface(updata).then(function (data) {
              toast.clear()
              if (data.code == 200) {

                that.$store.commit("setfacelist", data.data);

                that.$router.push("/facedetail");

              } else {
                Toast(data.msg)

              }
            }).catch(function (err) {
              toast.clear()
              console.log(err);
            })

          },
          uploadossimg(ossdata,file){
            var that = this;
            var keyname = ossdata.dir + this.getCurrentTime()+file.file.name;
            let fromData = new FormData();
            fromData.set('key',keyname)
            fromData.set('policy',ossdata.policy)
            fromData.set('OSSAccessKeyId',ossdata.accessid)
            fromData.set('success_action_status',200)
            fromData.set('signature',ossdata.signature)
            fromData.set('file',file.file)
            fromData.set('expire',ossdata.expire)
            fromData.set('dir',ossdata.dir)
            fromData.set('callback',ossdata.callback)
            axios.post(ossdata.host, fromData,{
              "Content-Type":"multipart/form-data",
              "timeout": 1000 * 60 * 10,      }).then((res) => {
              that.imglist[file.index].status='done';
              that.imglist[file.index].message='上传成功';
              console.log(res)
              that.uploadedimg =keyname.toString();
              that.getimgurl();
            }).catch(function (err) {
              console.log(err);
              that.imglist[file.index].status='failed';
              that.imglist[file.index].message='上传失败';

            })


          },
          getimgurl(){
            var that =this;
            generateUrl({key:this.uploadedimg}).then(function (data) {
              if (data.code == 200) {
                that.ossimgurl = data.data;

              } else {
                Toast(data.msg)

              }
            }).catch(function (err) {
              console.log(err);
            })

          },

          getCurrentTime() {
            //获取当前时间并打印
            var _this = this;
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth()+1;
            let dd = new Date().getDate();
            let hh = new Date().getHours();
            let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
            let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
            _this.localtime = yy+''+mm+''+dd+''+hh+''+mf+''+ss;
            return  _this.localtime;

          },

        },
      mounted() {
        this.id = this.$route.query.id;
      }
    };
</script>

<style scoped lang="css">
    .page {
        background-color: #f4f4f4;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }
    .header {


    }
    .space-y-15 > *:not(:first-child) {
        margin-top: 0.47rem;
    }
    .section_3 {
        padding: 3rem 1.72rem 13.53rem 1.75rem;
        flex: 1 1 auto;
        background-color: #ffffff;
        overflow-y: auto;
    }

    .section_4 {
        padding: 2.2rem 0;
        align-self: center;
        border-radius: 0.31rem;
        width: 7rem;
        border: dashed 0.061rem #333333;
    }
    .image_4 {
        width: 1.56rem;
        height: 1.5rem;
    }
    .text_3 {
        color: #8392ff;
        font-size: 0.63rem;
        font-family: MicrosoftYaHei;
        line-height: 0.56rem;
    }
    .space-y-34 > *:not(:first-child) {
        margin-top: 1.06rem;
    }
    .group_3 {
        padding-top: 1.03rem;
    }
    .group_4 {
        text-align: center;
    }
    .text_4 {
        color: #999999;
        font-size: 0.75rem;
        font-family: MicrosoftYaHei;
        line-height: 1.13rem;
    }
    .text_5 {
        color: #999999;
        font-size: 0.75rem;
        font-family: MicrosoftYaHei;
        line-height: 1.13rem;
    }
    .button {
        padding: 0.81rem 0 0.75rem;
        align-self: center;
        background-color: #eeeeee;
        border-radius: 1.25rem;
        width: 7.5rem;
    }
    .button_s {
      padding: 0.81rem 0 0.75rem;
      align-self: center;
      background-color: #8392ff;
      border-radius: 1.25rem;
      width: 7.5rem;
    }
    .text_6 {
        color: #999999;
        font-size: 0.94rem;
        font-family: MicrosoftYaHei;
        font-weight: 700;
        line-height: 0.94rem;
    }
    .text_6s {
      color: white;
      font-size: 0.94rem;
      font-family: MicrosoftYaHei;
      font-weight: 700;
      line-height: 0.94rem;
    }
    .text_7 {
        align-self: center;
        color: #999999;
        font-size: 0.63rem;
        font-family: MicrosoftYaHei;
        line-height: 0.63rem;
    }
</style>
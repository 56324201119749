<template>
  <div class="flex-col page">
    <div class="flex-col header">
    </div>
    <div class="flex-col items-start section_4">
      <div class="flex-row space-x-40 equal-division" v-if="typeindex==6" >
        <div class="flex-row space-x-11 equal-division-item" @click="changephototype(1)">
          <img
              :src="[typeindex == 1 ? require('../../assets/imgs/img_scene.png'):require('../../assets/imgs/img_scene_no.png')]"
              class="image_5"
          />
          <span :class="[typeindex == 1 ? 'text_5_yes':'text_5']">风景照片</span>
        </div>
        <div class="flex-row equal-division-item" @click="changephototype(2)">
          <img
              :src="[typeindex == 2 ? require('../../assets/imgs/img_person.png'):require('../../assets/imgs/img_person_no.png')]"
              class="image_5"
          />
          <span :class="[typeindex == 2 ? 'text_5_yes':'text_5']">人物照片</span>
        </div>
        <div class="flex-row space-x-13 equal-division-item" @click="changephototype(3)">
          <img
              :src="[typeindex == 3 ? require('../../assets/imgs/img_video.png'):require('../../assets/imgs/img_video_no.png')]"
              class="image_5"
          />
          <span :class="[typeindex == 3 ? 'text_5_yes':'text_5']">视频</span>
        </div>
      </div>
    </div>
    <div class="flex-col group_2">
      <div class="flex-row space-x-20 waterfall" v-if="datalist.length>0">
        <van-pull-refresh style="height:100%;width:100%;overflow-y:auto;" v-model="loading" @refresh="onRefresh" >
          <van-list
              v-model="addloading"
              :finished="finished"
              style=" display: flex;"
              @load="adddatalist">
            <van-cell  style="width: 49%;padding: 4px 4px">

              <div  v-for="(item, index) in datalist" :key="index">
              <van-image
                   v-if="typeindex !=3 && index%2==0"
                  :src="item.resourceUrl"
                  :lazy-load="true"
                  :show-loading="true"
                  @click="goto_buy(item,index)"

              >

                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>

                <van-image
                    v-if="typeindex ==3 && index%2==0"
                    :src="item.thumbnailUrl"
                    :lazy-load="true"
                    :show-loading="true"
                    @click="goto_buy(item,index)"

                >
                  <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>

</div>

            </van-cell>

            <van-cell   style="width: 49%;padding: 4px 4px">
              <div  v-for="(item, index) in datalist" :key="index">

              <van-image
                  v-if="typeindex !=3 &&index%2==1"
                  :src="item.resourceUrl"
                  :lazy-load="true"
                  @click="goto_buy(item,index)"

              >

              </van-image>

                <van-image
                    v-if="typeindex ==3 && index%2==1"
                    :src="item.thumbnailUrl"
                    :lazy-load="true"
                    @click="goto_buy(item,index)"

                >
                </van-image>
<!--                <video-->
<!--                    @click.stop="goto_buy(item,index)"-->
<!--                    x5-video-player-fullscreen="true"-->
<!--                    x5-playsinline-->
<!--                    playsinline-->
<!--                    webkit-playsinline-->
<!--                    poster="../../assets/imgs/7a61a2c69c40e68221b8aef546879074.png"-->
<!--                    controlslist="nodownload nofullscreen noremoteplayback noplaybackrate"-->
<!--                    style="display: block;-->

<!--    width: 100%;-->
<!--    height: 100%;"-->
<!--                    v-if="typeindex ==3 && index%2==1" :controls ="false" :src="item.resourceUrl">-->
<!--                  您的浏览器不支持video-->
<!--                </video>-->
              </div>

            </van-cell>


<!--            <div class="flex-col items-center  group_3">-->
<!--              <van-image-->
<!--                  :src="item.miniResourceUrl"-->
<!--                  :lazy-load="true"-->
<!--                  class="waterfall-item"-->
<!--                  @click="goto_buy(item,index)"-->
<!--                  v-for="(item, index) in datalist" :key="index"-->
<!--              />-->
<!--            </div>-->
          </van-list>
        </van-pull-refresh>

      </div>
      <div   style="align-self: center;margin-top: 20px;color: #999999;"  v-if="datalist.length ==0"> <img style="width: 100%" src="../../assets/imgs/img_null.png"></div>
      <span style="align-self: center;color: #999999;"  v-if="datalist.length ==0">暂无数据，请等待上传</span>

      <div class=" items-center section_3" style="display: flex;justify-content: space-around;">
<!--        <div style="width: 60%" >-->
<!--          <van-search v-model="value"   show-action background="transparent"   shape="round" placeholder="请输入口令" >-->

<!--            <template #action>-->
<!--              <div style=" background-color: #8392ff;-->
<!--  border-radius: 1.25rem; color: #ffffff ; padding: 0 1rem "  @click="onClickButton">确认</div>-->
<!--            </template>-->
<!--          </van-search>-->
<!--        </div>-->

        <van-button style="width: 30%;height: 2.5rem;" round block type="primary"  @click="this.$router.go(-1);">
          返回
        </van-button>
        <div  class="flex-row button" @click="goto_facetime" v-if="typeindex ==1">
          <img src="../../assets/imgs/img_scanface.png"
              class="image_13"/>
          <span class="text_3">人脸搜索</span>
        </div>
      </div>
    </div>
  </div>
  <van-popup v-model:show="imgshow" @click="this.imgshow =false"
             style="height: 100%;width:100%;background-color: rgba(0,0,0,0.5)" @close="popupclose">

    <div  class="custom_close"><img style="width: 30px;" src="../../assets/imgs/icon_close.png"> </div>
    <van-swipe :show-indicators="false" ref="swiper" @change="onchange" :style="heightwidthStyle"   >
      <van-swipe-item v-for="(item, index) in datalist" :key="index" :style="preimgStyle"  style="display: flex" >
        <img  @click.stop="ImagePreview(item);"  v-if="typeindex!=3" :src="item.resourceUrl" :style="preimgStyle" style="align-self: center;"/>
        <video  ref="videoView"  controlslist="nodownload" @click.stop="" v-if="typeindex==3" :src="item.resourceUrl" :style="preimgStyle" style="align-self: center;" :controls="true"/>

      </van-swipe-item>
      <template #indicator="{ active, total }">
        <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
      </template>
    </van-swipe>


    <div class="flex-col space-y-21">
      <div class="flex-row justify-between section_bottom" v-if="userinfo.isVip == 0" @click.stop="showvipdialog">
        <div class="flex-col items-start space-y-8">
          <span>您还不是会员哦</span>
          <div class="group_5">
            <span class="text_4">开通会员立省</span>
            <span class="text_5">{{ vipprice - normalprice }}</span>
            <span class="text_6">元</span>
          </div>
        </div>
        <div class="flex-col items-center text-wrapper_1"><span class="text_7">立即开通</span></div>
      </div>
      <div class="flex-row justify-between section_5">
        <div class="flex-row space-x-9 group_6">

          <div  class="flex-col items-center text-wrapper_2" @click.stop="this.imgshow =false"><span class="text_10">返回</span></div>

          <!--          <span class="text_8" v-if="userinfo.isVip == 0">原价：{{ normalprice }}</span>-->
<!--          <span class="text_9" v-if="userinfo.isVip == 0">会员价：{{ vipprice }}</span>-->

<!--          <span class="text_8" v-if="userinfo.isVip == 1">会员价：{{ vipprice }}</span>-->
<!--          <span class="text_9" v-if="userinfo.isVip == 1">原价：{{ normalprice }}</span>-->
        </div>
        <div  class="flex-col items-center text-wrapper_2" v-if="typeindex ==2" @click.stop="goto_detail"><span class="text_10">下载图片</span></div>
        <div  class="flex-col items-center text-wrapper_2" v-if="typeindex ==1" @click.stop="goto_detail"><span class="text_10">下载高清照片</span></div>
        <div  class="flex-col items-center text-wrapper_2" v-if="typeindex ==3" @click.stop="goto_detail"><span class="text_10">下载视频</span>

        </div>
<!--        <van-uploader :after-read="afterRead"  v-if="typeindex ==1" @click.stop="">-->
<!--        <div class="flex-col items-center text-wrapper_2" ><span class="text_10">下载图片</span>-->

<!--        </div>-->
<!--        </van-uploader>-->
      </div>
    </div>
  </van-popup>


  <van-popup v-model:show="vipdailog" style="padding: 1.63rem 0.56rem 1.41rem; width: 100%;
  background-color: #ffffff;text-align: center;
  border-radius: 1.25rem 1.25rem 0px 0px;" @click="this.vipdailog =false" position="bottom">
    <span class="text_4s">开通会员</span>
    <div class="flex-col group_3s space-y-40">
      <div class="flex-row section_6s space-x-25">
        <div class="group_4s">
          <span class="text_5s">{{ vipprice }}</span>
          <span class="text_6s">元</span>
        </div>
        <span class="text_7s">即可成为终身会员</span>
      </div>
      <div class="flex-row space-x-14" style="align-items: center;"  @click.stop="changestatusclick">
        <div :class="changestatus(checkstatus)"></div>
        <div class="group_6s">
          <span class="font_1s text_8s">请仔细阅读开通中天图库</span>
          <span class="font_1s text_9s" @click.stop="jumprules">《会员协议》</span>
        </div>
      </div>
    </div>
    <div class="flex-col items-center buttonss space-y-9" @click.stop="getvip">
      <span class="text_10s">开通终身会员</span>
      <span class="text_11s">会员可享受会员优惠</span>
    </div>
  </van-popup>
</template>

<script>
import {getPriceConfig, getResourceList,validateCode }from "../../api/index"
import { Lazyload } from 'vant';

import {Toast} from "vant";
import {vipprice, registervip, getUserInfo, uploadfile, generateUrl, compareFace} from "@/api/unit";
import axios from "axios";
import {ImagePreview} from "vant";

export default {
  components: {},
  data() {
    return {
      value:"",
      typeindex: 1,
      loading: false,
      addloading: false,
      finished: true,
      id: '',
      datalist: [],
      dataitem: {},
      paramdata: {
        pageNum: 1,
        pageSize: 20,
        activityId: '',
        resourceType: 2,
      },
      //浏览图片
      imgshow: false,
      priceconfig: [],
      userinfo: {
        isVip: 0,
      },
      checkstatus: false,
      vipdailog: false,
      vipprice: 0,
      imgsrc:'',
      uploadedimg:'',
      imglist:[],
      ossimgurl:'',
    };
  },
  computed: {

    heightwidthStyle() {
      // height: '+window.innerHeight+'px'
      return      'height: '+window.innerHeight+'px;'+'width: ' + window.innerWidth + 'px';
    },
    preimgStyle() {
      // height: '+window.innerHeight+'px'
      return 'width: ' + window.innerWidth + 'px;';
    },
    normalprice() {

      return (this.priceconfig[this.typeindex].price) / 100;
    },
    vipprice() {

      return (this.priceconfig[this.typeindex].vipPrice) / 100;
    }
  },
  methods: {
    onClickButton(){


      if (!this.value){

        Toast("请输入口令!")
        return;
      }



      var that = this;

      validateCode({
        code:this.value,
        actId:this.id
      }).then(function (data) {
        if (data.code == 200) {
          Toast("以获取活动图片")
        }else {
          // Toast("以获取活动图片")
        }
      }).catch(function (err) {
        console.log(err);
      })
    },
    popupclose(index){
      if (this.typeindex==3){
        const videoElement = this.$refs.videoView.length;
        console.log(videoElement);
        if (videoElement > 0) {
          for (let i = 0; i < videoElement; i++) {
            this.$refs.videoView[i].pause();
          }
        }
      }

    },
    ImagePreview(item){
      console.log(item)

      ImagePreview({images: [item.resourceUrl] ,showIndex:false,closeable:true,});
    },

    show_before_img(){
      this.instance_before = ImagePreview({
        images: [this.warsher_brfore],
      });
    },
    show_after_img(){
      this.instance_after = ImagePreview({
        images: [this.warsher_after],
      });
    },

    afterRead(file){
      file.status ='uploading'
      file.message = '上传中...';
      console.log(file)
      this.imgsrc = file.content;
      this.imglist.push(file);
      file.index = this.imglist.length-1;
      var that =this;
      uploadfile().then(function (data) {
        if (data.code == 200) {
          that.uploadossimg(data.data,file)

        } else {
          Toast(data.msg)

        }
      }).catch(function (err) {
        console.log(err);
      })

    }, uploadossimg(ossdata,file){
      var that = this;
      var keyname = ossdata.dir + this.getCurrentTime()+file.file.name;
      let fromData = new FormData();
      fromData.set('key',keyname)
      fromData.set('policy',ossdata.policy)
      fromData.set('OSSAccessKeyId',ossdata.accessid)
      fromData.set('success_action_status',200)
      fromData.set('signature',ossdata.signature)
      fromData.set('file',file.file)
      fromData.set('expire',ossdata.expire)
      fromData.set('dir',ossdata.dir)
      fromData.set('callback',ossdata.callback)
      axios.post(ossdata.host, fromData,{
        "Content-Type":"multipart/form-data",
        "timeout": 1000 * 60 * 10,      }).then((res) => {
        that.imglist[file.index].status='done';
        that.imglist[file.index].message='上传成功';
        console.log(res)
        that.uploadedimg =keyname.toString();
        that.getimgurl();
      }).catch(function (err) {
        console.log(err);
        that.imglist[file.index].status='failed';
        that.imglist[file.index].message='上传失败';

      })


    },
    getimgurl(){
      var that =this;
      generateUrl({key:this.uploadedimg}).then(function (data) {
        if (data.code == 200) {

          that.seachface(data.data);



        } else {
          Toast(data.msg)

        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    seachface(imgurl){
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中..."
      });
      var that = this;
      var updata ={
        imageURLA:imgurl,
        imageURLB:this.dataitem.resourceUrl

      }
      compareFace(updata).then(function (data) {

        toast.clear();
        if (data.code == 200) {
          console.log(data)

        } else {
          Toast(data.msg)

        }
      }).catch(function (err) {

        toast.clear();
        console.log(err);
      })

    },

    changestatus() {
      if (this.checkstatus) {
        return 'circle'
      } else {
        return 'circle_uneck'
      }


    }, changestatusclick() {
      this.checkstatus = !this.checkstatus;
    },
    goto_facetime() {
      this.$router.push("/facetime?id="+this.id);
    },
    goto_buy(item, index) {
      // this.$router.push("/viewimgs?activityId=" + item.activityId + "&id=" + item.id);
      this.dataitem = item;
      this.imgshow = true;
      if (this.$refs.swiper) {
        this.$refs.swiper.swipeTo(index, {immediate: true});
      } else {
        this.$nextTick(() => {
          this.$refs.swiper.swipeTo(index, {immediate: true});
        })
      }


    },
    goto_detail() {
      console.log(this.$store.state.istest)
      if (!this.$store.state.istest) {
        this.$router.push("/imgdetail?id=" + this.dataitem.id);
        // window.location.href ='/imgdetail?id='+ this.dataitem.id
      } else {
        this.$router.push("/imgdetail?id=" + this.dataitem.id);
      }



    },
    showvipdialog() {
      this.vipdailog = true;

    },
    onchange(index) {
      console.log(index)
      this.dataitem.id = this.datalist[index].id
      if (this.typeindex==3){
        const videoElement = this.$refs.videoView.length;
        console.log(videoElement);
        if (videoElement > 0) {
          for (let i = 0; i < videoElement; i++) {
            this.$refs.videoView[i].pause();
          }
        }
      }

    },
    onRefresh() {

      this.changephototype(this.typeindex);
      setTimeout(() => {
        Toast('刷新成功');
        this.loading = false;
      }, 1000);
    },
    changephototype(index) {
      this.typeindex = index;
      this.paramdata.pageNum = 1;
      this.paramdata.resourceType = index;
      this.datalist = [];
      this.getresourcelist(this.typeindex);


    }, imgError(item) {
      console.log(axios.defaults.baseURL + item.resourceUrl);
      // axios.defaults.baseURL
      item.resourceUrl = axios.defaults.baseURL + item.resourceUrl;
    },
    getresourcelist(type) {
      this.id = this.$route.query.id
      this.paramdata.activityId = this.id;
      var that = this;
      getResourceList(this.paramdata).then(function (data) {
        console.log(data);
        if (data.code == 200) {
          that.datalist = data.rows;
          if (that.datalist.length >= data.total) {
            that.finished = true;
          } else {
            that.finished = false;
          }
        }
      }).catch(function (err) {
        console.log(err);
      })
    },
    adddatalist() {
      this.finished = true;
      this.addloading = true;
      this.paramdata.pageNum++;
      console.log(this.paramdata.pageNum);
      var that = this;
      getResourceList(this.paramdata).then(function (data) {
        if (data.code == 200) {
          that.datalist.push.apply(that.datalist, data.rows);
          if (that.datalist.length >= data.total) {
            that.finished = true;
          }else {
            that.finished = false;
          }
        }
        that.addloading = false;
      }).catch(function (err) {
        console.log(err);
      })
    },
    getvip(){
      if(!this.checkstatus){
        Toast('请阅读并同意相关协议');
        return;
      }
      var that = this;
      registervip().then(function (data) {
        if (data.code == 200) {
          var orderdata =data.data;

          WeixinJSBridge.invoke(
              'getBrandWCPayRequest', {
                "appId":that.$store.state.APPID,     //公众号ID，由商户传入
                "timeStamp": orderdata.timeStamp,         //时间戳，自1970年以来的秒数
                "nonceStr":orderdata.nonceStr, //随机串
                "package":orderdata.packageValue,
                "signType":orderdata.signType,         //微信签名方式：
                "paySign":orderdata.paySign //微信签名
              },
              function(res){
                if(res.err_msg == "get_brand_wcpay_request:ok" ){
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                  alert('购买成功')
                  that.vipdailog=false;
                  that.getuserinfo();
                }
              });

        } else {
          Toast(data.msg)

        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    getuserinfo(){
      var that = this;
      getUserInfo().then(function (data) {
        if (data.code == 200) {
          that.userinfo = data.data;
        that.$store.commit("setuserinfo", data.data);
        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    jumprules(){
      this.$router.push('/rules');
    },

    getCurrentTime() {
      //获取当前时间并打印
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth()+1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
      _this.localtime = yy+''+mm+''+dd+''+hh+''+mf+''+ss;
      return  _this.localtime;

    },

  },
  mounted() {

    this.userinfo = this.$store.state.userinfo
    this.$store.commit('setcompare',false);
    console.log(this.userinfo);
    this.paramdata.resourceType = this.typeindex;
    this.typeindex = this.$route.query.type
    this.paramdata.resourceType=this.$route.query.type
    this.getresourcelist(this.typeindex);
    var that = this;

    getPriceConfig().then(function (data) {
      console.log(data);
      if (data.code == 200) {
        that.priceconfig = data.data;
      }
    }).catch(function (err) {
      console.log(err);
    })

    vipprice().then(function (data) {
      if (data.code == 200) {
        that.vipprice = data.data / 100;

      }
    }).catch(function (err) {
      console.log(err);
    })

  }

};
</script>

<style scoped lang="css">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.header {
  /*height: 1rem;*/
}


.custom-indicator {
  position: absolute;
  left: 5px;
  top: 5px;
  padding: 2px 5px;
  font-size: 12px;
  color: white;
  border-radius: 0.65rem;
  background: rgba(0, 0, 0, 0.5);
}

.custom_close {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 2px 5px;
  font-size: 12px;
  color: white;

}

.section_1 {
  padding: 0.25rem 0.5rem 0.28rem;
  background-color: #ffffff;
}

.space-x-12 > *:not(:first-child) {
  margin-left: 0.38rem;
}

.image {
  flex-shrink: 0;
  width: 2.41rem;
  height: 0.69rem;
}

.text {
  color: #333333;
  font-size: 0.75rem;
  font-family: Hiragino Sans GB;
  line-height: 0.66rem;
}

.space-x-2 > *:not(:first-child) {
  margin-left: 0.063rem;
}

.text_1 {
  margin-top: 0.063rem;
  color: #333333;
  font-size: 0.75rem;
  font-family: Hiragino Sans GB;
  line-height: 0.59rem;
}

.image_1 {
  flex-shrink: 0;
  width: 1.66rem;
  height: 0.69rem;
}

.section_2 {
  padding: 0.91rem 0.59rem 0.81rem;
  background-color: #ffffff;
}

.image_2 {
  margin: 0.13rem 0;
  width: 0.81rem;
  height: 0.78rem;
}

.space-y-21 > *:not(:first-child) {
  margin-top: 0.66rem;
}

.space-y-21 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.text_2 {
  color: #111111;
  font-size: 1.06rem;
  font-family: Hiragino Sans GB;
  font-weight: 700;
  line-height: 1.03rem;
}

.image_3 {
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}

.group_2 {

  flex: 1 1 auto;

  overflow-y: auto;
  position: relative;
}

.space-x-20 > *:not(:first-child) {
  margin-left: 0.63rem;
}

.waterfall {
  height: 100%;
  padding-bottom: 30px;
}

.space-y-19 > *:not(:first-child) {
  margin-top: 0.59rem;
}

.group_3 {

  margin: 0.66rem 0 -5rem;
  padding: 0 0.63rem 0.63rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.67rem;
  column-gap: 0.67rem;
}

.waterfall-item {
  width: 100%;

}

.group_4 {
  flex: 1 1 9.06rem;
}

.waterfall-item_1 {
  width: 100%;
}

.section_3 {
  padding: 0.31rem 0;
  background-color: #00000080;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}


.button {
  padding: 0.63rem 1.22rem 0.63rem 1.25rem;
  background-color: #8392ff;
  border-radius: 1.25rem;
}

.image_13 {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
}
.text_3 {
  color: #ffffff;
  font-size: 0.94rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
}

.section_4 {
  padding: 0.52rem 0;
  background-color: #ffffff;
}

.equal-division {
  padding-left: 0.63rem;
  padding-right: 0.55rem;
}

.space-x-11 > *:not(:first-child) {
  margin-left: 0.34rem;
}

.equal-division-item {
  padding: 0.31rem 0.31rem;
}

.image_5 {
  flex-shrink: 0;
  width: 0.94rem;
  height: 0.94rem;
}

.text_4 {
  color: #8392ff;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 0.81rem;
}

.text_4_no {
  color: #333333;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 0.81rem;
}

.text_5_yes {
  margin: 0.063rem 0 0.063rem 0.34rem;
  color: #8392ff;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.81rem;
}

.text_5 {
  margin: 0.063rem 0 0.063rem 0.34rem;
  color: #333333;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.81rem;
}

.space-x-13 > *:not(:first-child) {
  margin-left: 0.41rem;
}

.text_6 {
  color: #333333;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.81rem;
}


.section_5 {
  padding: 0.28rem 0.56rem;
  background-color: #ffffff;
}

.group_6 {
  align-self: center;
}

.space-x-9 > *:not(:first-child) {
  margin-left: 0.28rem;
}

.text-wrapper_2 {
  margin-right: 0.063rem;
  padding: 0.81rem 0;
  background-color: #8392ff;
  border-radius: 1.25rem;
  width: 6.25rem;
  height: 2.5rem;
}

.text_8 {
  margin-bottom: 0.063rem;
  color: #ff5a00;
  font-size: 1.5rem;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.09rem;
}

.text_9 {
  margin-top: 0.44rem;
  color: #999999;
  font-size: 0.75rem;
  font-family: Hiragino Sans GB;
  line-height: 0.72rem;
  text-decoration: line-through;
}

.text_10 {
  color: #ffffff;
  font-size: 0.94rem;
  font-family: Hiragino Sans GB;
  font-weight: 700;
  line-height: 0.88rem;
}

.section_bottom {
  margin-left: 0.59rem;
  margin-right: 0.66rem;
  padding: 0.63rem 0.66rem 0.66rem;
  border-radius: 0.31rem;
  background-image: url('../../assets/imgs/icon_vip_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text-wrapper_1 {
  margin: 0.19rem 0.28rem 0.094rem 0;
  padding: 0.47rem 0;
  background-color: #fff2e5;
  background-image: linear-gradient(0deg, #f4dcc4 0%, #ffe3d3 100%);
  border-radius: 0.94rem;
  width: 5rem;
  height: 1.88rem;
}

.text_7 {
  color: #bd6d40;
  font-size: 0.94rem;
  font-family: MicrosoftYaHei;
  line-height: 0.94rem;
}

.circle {
  background: #8392ff;
  /*border: 0.1em ;*/
  border-radius: 50%;
  /*box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);*/
  height: 1em;
  width: 1em;
}

.circle_uneck {
  /*background: #8392ff;*/
  border: 0.03em solid;
  border-radius: 50%;
  /*box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);*/
  height: 1em;
  width: 1em;
}

.text_4s {
  align-self: center;
  color: #333333;
  font-size: 0.94rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 0.91rem;
}

.group_3s {
  margin-top: 1.41rem;
}

.space-y-40 > *:not(:first-child) {
  margin-top: 1.25rem;
}

.section_6 {
  margin-right: 0.094rem;
  padding: 1.13rem 0.81rem 0.84rem;
  border-radius: 0.31rem;
  background-image: url('../../assets/imgs/2af78a9e7aa7dadc93d9f0cf025bbeb3.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.space-x-25 > *:not(:first-child) {
  margin-left: 0.78rem;
}


.section_6s {
  margin-right: 0.094rem;
  padding: 1.13rem 0.81rem 0.84rem;
  border-radius: 0.31rem;
  background-image: url('../../assets/imgs/2af78a9e7aa7dadc93d9f0cf025bbeb3.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.space-x-25 > *:not(:first-child) {
  margin-left: 0.78rem;
}
.group_4s {
  margin-bottom: 0.063rem;
  height: 1.41rem;
}
.text_5s {
  color: #ff5046;
  font-size: 1.88rem;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.38rem;
}
.text_6s {
  color: #ff5046;
  font-size: 0.75rem;
  font-family: MicrosoftYaHei;
  line-height: 0.66rem;
}
.text_7s {
  color: #3a2f28;
  font-size: 1.5rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 1.47rem;
}
.space-x-14 > *:not(:first-child) {
  margin-left: 0.44rem;
}
.image_4s {
  width: 0.94rem;
  height: 0.94rem;
}
.group_6s {
  margin-bottom: 0.063rem;
  line-height: 0.84rem;
  height: 0.84rem;
}
.font_1s {
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.84rem;
}
.text_8s {
  color: #666666;
}
.text_9s {
  color: #8392ff;
}
.buttonss {
  margin-top: 1.84rem;
  padding: 0.41rem 0 0.28rem;
  align-self: center;
  background-color: #8392ff;
  border-radius: 1.25rem;

}
.space-y-9 > *:not(:first-child) {
  margin-top: 0.28rem;
}
.text_10s {
  color: #ffffff;
  font-size: 0.94rem;
  font-family: Hiragino Sans GB;
  font-weight: 700;
  line-height: 0.91rem;
}
.text_11s {
  color: #fff600;
  font-size: 0.63rem;
  font-family: Hiragino Sans GB;
  line-height: 0.63rem;
}
</style>
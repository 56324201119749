<template>
  <div className="flex-col page">

    <div class="flex-col page">
      <div class="flex-col section_3">
        <div class="flex-row justify-between">
          <div class="flex-col items-center space-y-8">
            <span class="font_2">欢迎来到中天图库~</span>
            <span class="text_5">注册成为中天图库摄影师</span>
          </div>
          <div class="flex-col items-center text-wrapper"><span class="text_4">4/4</span></div>
        </div>
        <div class="flex-col items-start group">
          <span class="font_2 text_6">签约摄影师为什么要缴费？有哪些好处？</span>
          <span class="font_3 text_7">1、上传数量无限制</span>
          <span class="font_3 text_8">2、收益佣金比例更高</span>
          <span class="font_3 text_9">3、更多平台线下业务合作</span>
        </div>
        <div class="flex-col items-center group_2">
          <img class="image_4" src="../../assets/imgs/bg_masterpay.png"/>
          <div class="flex-col items-center section_4">
            <div style="font-family: Microsoft YaHei;
font-weight: 800;
font-size: 26px;
color: #FFFFFF;">￥{{ vipdata }}
            </div>
          </div>
        </div>
        <div class="flex-row group_3 space-x-21">
          <div :class="changestatus(checkstatus)" @click="changestatusclick"></div>
          <div class="group_4">
            <span class="font_4 text_11">我同意</span>
            <span class="font_4 text_12" @click=" this.$router.push('/viprules');">《中天图库拍摄协议》</span>
          </div>
        </div>
        <div class="flex-col items-center text-wrapper_3" @click="onSubmit"><span class="text_13">注册</span></div>
      </div>
    </div>

  </div>
</template>

<script>
import {Toast} from "vant";
import {getContractPrice, registermasterpg} from "@/api/unit";

export default {
  components: {},
  data() {
    return {
      data: {
        bankBranch: '',
        bankCard: '',
        isApproval: true,
      },
      checkstatus: false,
      vipdata: 0,


    };
  },

  methods: {
    changestatus() {
      if (this.checkstatus) {
        return 'circle'
      } else {
        return 'circle_uneck'
      }


    }, changestatusclick() {
      this.checkstatus = !this.checkstatus;
    },
    onSubmit(values) {
      //提交
      var that = this;
      if (!this.checkstatus) {
        Toast('请阅读并同意相关协议');
        return;
      }

      registermasterpg(that.data).then(function (data) {
        if (data.code == 200) {
          var orderdata = data.data;

          WeixinJSBridge.invoke(
              'getBrandWCPayRequest', {
                "appId": that.$store.state.APPID,     //公众号ID，由商户传入
                "timeStamp": orderdata.timeStamp,         //时间戳，自1970年以来的秒数
                "nonceStr": orderdata.nonceStr, //随机串
                "package": orderdata.packageValue,
                "signType": orderdata.signType,         //微信签名方式：
                "paySign": orderdata.paySign //微信签名
              },
              function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                  alert('购买成功')
                  that.getdata();
                }
              });

        } else {
          Toast(data.msg)

        }
      }).catch(function (err) {
        console.log(err);
      })
    },


  },
  mounted() {
    var that = this;
    getContractPrice().then(function (data) {
      if (data.code == 200) {
        that.vipdata = data.data / 100;

      } else {
        Toast(data.msg)

      }
    }).catch(function (err) {
      console.log(err);
    })
  }
};
</script>

<style scoped lang="css">.page {
  padding-top: 0.063rem;
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section_3 {
  padding: 1.28rem 0.91rem 3.88rem;
  background-color: #ffffff;
}

.space-y-8 > *:not(:first-child) {
  margin-top: 0.25rem;
}

.font_2 {
  font-size: 0.94rem;
  font-family: MicrosoftYaHei;
  line-height: 0.94rem;
  font-weight: 700;
  color: #333333;
}

.text_5 {
  color: #666666;
  font-size: 0.75rem;
  font-family: MicrosoftYaHei;
  line-height: 0.75rem;
}

.text-wrapper {
  margin-right: 0.063rem;
  padding: 0.13rem 0;
  border-radius: 0.44rem;
  width: 1.56rem;
  height: 0.88rem;
  border: solid 0.031rem #999999;
}

.text_4 {
  color: #666666;
  font-size: 0.63rem;
  font-family: MicrosoftYaHei;
  line-height: 0.56rem;
}

.group {
  margin-top: 1.88rem;
}

.text_6 {
  line-height: 0.97rem;
}

.font_3 {
  font-size: 0.81rem;
  font-family: MicrosoftYaHei;
  line-height: 0.84rem;
  color: #666666;
}

.text_7 {
  margin-left: 0.094rem;
  margin-top: 0.94rem;
  line-height: 0.81rem;
}

.text_8 {
  margin-left: 0.063rem;
  margin-top: 0.34rem;
  line-height: 0.78rem;
}

.text_9 {
  margin-left: 0.063rem;
  margin-top: 0.41rem;
  line-height: 0.81rem;
}

.group_2 {
  margin-top: 1.88rem;
  padding-top: 1.09rem;
  position: relative;
}

.image_4 {
  border-radius: 0.31rem;
  width: 12.38rem;
  height: 6.66rem;
}

.section_4 {
  padding: 2.0rem 0 0.63rem;
  border-radius: 0.31rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 12.31rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.text-wrapper_2 {
  padding: 0.5rem 0 0.44rem;
  background-color: #fff100;
  box-shadow: 0px 0.16rem 0.19rem #2464cf4d;
  background-image: linear-gradient(0deg, #63e0ff 0%, #69b7ff 49%, #b6ebff 100%);
  border-radius: 0.94rem;
  width: 5.63rem;
}

.text_10 {
  color: #ffffff;
}

.group_3 {
  margin-top: 2.19rem;
}

.space-x-21 > *:not(:first-child) {
  margin-left: 0.66rem;
}

.image_5 {
  width: 0.94rem;
  height: 0.94rem;
}

.group_4 {
  margin-bottom: 0.063rem;
  line-height: 0.84rem;
  height: 0.84rem;
}

.font_4 {
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.84rem;
}

.text_11 {
  color: #aaaaaa;
}

.text_12 {
  color: #8392ff;
}

.text-wrapper_3 {
  margin-right: 0.063rem;
  margin-top: 1.94rem;
  padding: 0.91rem 0 0.84rem;
  background-color: #8392ff;
  border-radius: 1.38rem;
}

.text_13 {
  color: #fefefe;
  font-size: 1rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 1rem;
}

.circle {
  background: #8392ff;
  /*border: 0.1em ;*/
  border-radius: 50%;
  /*box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);*/
  height: 1em;
  width: 1em;
}

.circle_uneck {
  /*background: #8392ff;*/
  border: 0.03em solid;
  border-radius: 50%;
  /*box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);*/
  height: 1em;
  width: 1em;
}
</style>
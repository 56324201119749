<template>
  <div class="flex-col page">
    <div class="flex-col section_3 space-y-52">
      <span class="text_3">《中天图库用户协议》</span>
      <div>
        <span class="font_1">
          欢迎您使用中天图库用户服务！
为使用中天图库用户服务（简称为：本服务），请您仔细阅读、理解并遵守《中天图库用户服务协议》（简称为：本协议）。

           <br />
        </span>

        <span class="font_1">
          一、中天图库用户
   1. 用户服务是中天图库官方公众号中设立的服务项目。
     2．您在申请开通中天图库用户流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容。如您对协议有任何疑问，可向中天图库客服咨询。
     3．当您按照中天图库用户开通页面的提示，完成全部开通程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与中天图库达成一致，成为中天图库用户。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止开通程序。若您开通用户后以任何理由提出未部分或全部阅读本协议或非本人点击等其他情况而拒绝遵守用户条款，视为您已违反中天图库用户协议。
4. 中天图库用户属于虚拟产品，基于虚拟产品的性质和特征，您所获取的虚拟产品不能够进行退货、换货、兑换现金等操作。

          <br />
        </span>
        <span class="font_1">二、用户等级及权利
     中天图库用户等级分为一般用户和会员两个级别。凡关注“中天图库”公众号的用户即为一般用户，可以浏览网站图片以及视频内容，可以原价下载风景图片、视频以及人像图片中的本人图片。一般用户提出申请并交纳相应费用后即可成为会员，会员可以浏览网站图片以及视频内容，享有折扣价格下载风景图片、视频以及人像图片中的本人图片的权利。
</span>
        <span class="font_1">
          一、中天图库用户
   1. 用户服务是中天图库官方公众号中设立的服务项目。
     2．您在申请开通中天图库用户流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容。如您对协议有任何疑问，可向中天图库客服咨询。
     3．当您按照中天图库用户开通页面的提示，完成全部开通程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与中天图库达成一致，成为中天图库用户。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止开通程序。若您开通用户后以任何理由提出未部分或全部阅读本协议或非本人点击等其他情况而拒绝遵守用户条款，视为您已违反中天图库用户协议。
4. 中天图库用户属于虚拟产品，基于虚拟产品的性质和特征，您所获取的虚拟产品不能够进行退货、换货、兑换现金等操作。

          <br />
        </span>

        <span class="font_1">
          三、会员需承担的义务
   1．尊重中天图库的知识产权，保护中天图库的商业形象，遵守中天图库的会员制度。
     2. 中天图库用户必须遵循：
    （1）关于网络的相关法律法规。
    （2）正确使用传播服务不作非法用途。
    （3）不干扰或混乱网络服务。
    （4）遵守所有使用网络服务的网络协议、规定、程序和惯例。
（5）其他应当遵守的规则。

          <br /></span>
        <span class="font_1">
         四、中天图库用户权益提示
   中天图库会员权益具体以中天图库官方展示页面说明的服务为准。中天图库有权根据法律规定及政策变更、版权方要求、运营需求等变动进行中天图库用户及会员服务内容的全部或部分变更、调整、取消、增加，并且就前述权益的调整，中天图库将在相应服务页面或以其他合理方式进行发布，并在发布后即刻生效。发布后，当您采用接受服务的形式进行下一步操作或继续使用本服务，即表明您已经仔细阅读并接受中天图库更新后的用户权益；如您对本协议的修改有任何问题，可以咨询客服。


          <br /></span>
        <span class="font_1">
       五、相关法律责任
中天图库是为本网站注册摄影师提供照片展示的第三方平台，所有在本网站展示的图片、视频，由摄影师负责其合法性，保障不侵犯著作权、肖像权、名誉权、隐私权等在内的合法权益，并由摄影师承担相关法律责任，本网站不承担任何法律责任。
          <br /></span>
        <span class="font_1">
          六、申请会员流程
     1.在中天图库“个人中心”页面，以及在浏览下载图片视频过程中均可看到购买会员入口，按照提示操作支付成功即表示购买会员成功；
     2. 中天图库会员为终身制，一次购买终身享有会员优惠；
3. 申请过程中的任何问题请及时联系客服咨询。  <br /></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="css">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.section_3 {
  padding: 1.94rem 1rem 9.38rem 1.47rem;
  background-color: #ffffff;
  overflow-y: auto;
}
.space-y-52 > *:not(:first-child) {
  margin-top: 1.63rem;
}
.text_3 {
  align-self: center;
  color: #333333;
  font-size: 1.06rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 1.06rem;
}
.font_1 {
  font-size: 0.88rem;
  font-family: Adobe Heiti Std;
  line-height: 1.25rem;
  color: #333333;
}
</style>
// 这个JS文件就是专门用于管理请求各种接口地址的
import Network from './network'


//活动列表
export const getActivityList = (data) => Network.post('/api/h5/cm/getActivityList',data)

//活动相片列表
export const getResourceList = (data) => Network.get('/api/h5/cm/getResourceList',data)


//验证活动(口令)
export const validateCode = (data) => Network.get('/api/h5/cm/validateCode',data)

//购买图片(口令)
export const getactivitybycode = (data) => Network.get('/api/h5/cm/createOrderByCode',data)

//资源价格配置
export const getPriceConfig = (data) => Network.get('/api/common/getPriceConfig',data)



//相片详情
export const getResourceDetail = (data) => Network.get('/api/h5/cm/getResourceDetail',data)

//获取原图下载链接
export const getDownloadUrl = (data) => Network.get('/api/h5/cm/getDownloadUrl',data)




//微信登录
export const wxmplogin = (data) => Network.post('/api/auth/wxmplogin',data)





<template>
  <div class="flex-col page space-y-77">
    <div class="flex-col justify-start">
      <div class="flex-row section_4">
        <div class="flex-row group_4">
          <div class="flex-col justify-start group_5">
            <div class="flex-row section_5 space-x-15">
              <img class="image_5" src="../../assets/imgs/5493a8ef3d961d34aa57d693503af75c.png" />
              <div class="flex-col justify-start items-start group_6">
                <img class="image_6" src="../../assets/imgs/1b9284b723c572c6b14e6ec6b6086f6a.png" />
                <img class="image_7" src="../../assets/imgs/5aa37881a963cff6207d7b53c839e087.png" />
              </div>
            </div>
            <img class="image_8" src="../../assets/imgs/c9b2be26f1bc23c5ed241810c32949f0.png" />
          </div>
          <img class="image_9" src="../../assets/imgs/aefc204fdee5ccd904139aaec1f5c966.png" />
        </div>
        <div class="group_7">
          <span class="font_2">
            应国家网信办实名制要求
            <br />
          </span>
          <span class="font_2">
            同时为了您的账户安全，
            <br />
          </span>
          <span class="font_2 text_6">请绑定手机号</span>
        </div>
      </div>
    </div>



    <van-form @submit="onSubmit" ref="formData" class="flex-col space-y-30 group_4">
      <van-cell-group inset>
        <van-field
            style="padding-left: 0"
            v-model="data.realname"
            label="姓名"
            name="realname"
            placeholder="请输入真实姓名"
            :rules="[{ required: false, message: '请填写用户名' }]"
        />

        <van-field
            style="padding-left: 0;"
            v-model="data.phone"
            label="手机号码"
            center
            clearable
            placeholder="请输入手机号码"
            :rules="[{ required: true, message: '请输入手机号码' }]">
          <template #button>
            <span @click="getphonecode" class="text_9">{{ content }}</span>
          </template>
        </van-field>

        <van-field
            style="padding-left: 0;"
            v-model="data.imgcode"
            center
            clearable
            label="验证码"
            placeholder="请输入验证码"
            :rules="[{ required: true, message: '请输入验证码' }]">
          <template #button>
            <img @click="getcheckcode" :src="checkcoddata.img"/>
          </template>
        </van-field>



        <van-field
            style="padding-left: 0;"
            v-model="data.vcode"
            center
            label="短信验证码"
            placeholder="请输入短信验证码"
            :rules="[{ required: true, message: '请输入短信验证码' }]">

        </van-field>

      </van-cell-group>
      <div style="margin: 16px;align-self: center">
        <div class="flex-col justify-start items-center text-wrapper"
             type="primary" native-type="submit" @click="clicknext"><span class="font_3 text_15">确认绑定</span></div>

      </div>
    </van-form>

  </div>
</template>

<script>
import {bindpgphone, binduserphone, captchaImage, userphonecode} from "@/api/unit";
import {Toast} from "vant";

export default {
  components: {},
  data() {
    return {
      checkcoddata:{},
      data:{
        imgcode:'',
        realname:'',
        phone:'',
        vcode:'',
      },
      content: '获取验证码',  // 按钮里显示的内容
      totalTime: 60,
      issend:false,
      uuid:'',



    };
  },
  mounted() {
    this.getcheckcode()

  },

  methods: {


    getcheckcode() {
      var that = this;
      //获取验证图形
      captchaImage().then(function (data) {
        console.log(data);
        if (data.code == 200) {
          var checkcodeurl =
              "data:image/jpg;base64," + data.data.img.replace(/[\r\n]/g, "");
          that.checkcoddata = data.data;
          that.checkcoddata.img = data.data.img;

        } else {
        }
      }).catch(function (err) {
        console.log(err);
      })
    },

    getphonecode() {
      var that = this;
      var phonecode = {
        "code": "",
        "phone": "",
        "uuid": ""
      }
      if (!this.data.phone) {
        Toast('请填写手机号码');
        return;
      }
      if (!this.data.imgcode) {
        Toast('请填写验证码');
        return;
      }
      if (this.issend){
        return;
      }

        phonecode.phone = this.data.phone;
        phonecode.code = this.data.imgcode;
        phonecode.uuid = this.checkcoddata.uuid;
        userphonecode(phonecode).then(function (data) {
          console.log(phonecode);
          if (data.code == 200) {
            that.uuid = data.data;
            that.issend =true;
            that.countDown();

          } else {
            Toast(data.msg);
            that.getcheckcode();
          }
        }).catch(function (err) {
          console.log(err);
        })



    },
    clicknext(values) {
      this.$refs.formData.submit();
    },
    onSubmit(values) {
      var that = this;
      if (!this.uuid){
        Toast('请验证手机号码是否正确')
        return;
      }

      var bind = {
        "code": this.data.vcode,
        "phone": this.data.phone,
        "uuid": this.uuid
      }
      binduserphone(bind).then(function (data) {
        if (data.code == 200) {
          Toast('绑定成功')
          this.$router.go(-1)
        }
      }).catch(function (err) {
        console.log(err);
      })
      //
    },

    countDown () {
      this.content = this.totalTime + 's后重新发送' //这里解决60秒不见了的问题
      let clock = window.setInterval(() => {
        this.totalTime--
        this.content = this.totalTime + 's后重新发送'
        if (this.totalTime < 0) {     //当倒计时小于0时清除定时器
          window.clearInterval(clock)
          this.issend=false;
          this.content = '重新发送验证码'
          this.totalTime = 60
        }
      },1000)
    },
  },
};
</script>

<style scoped lang="css">
.page {
  padding-bottom: 2rem;
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.space-y-77 > *:not(:first-child) {
  margin-top: 2.41rem;
}
.section_4 {
  padding: 0.69rem 1.31rem 0;
  background-color: #ffffff;
  background-image: linear-gradient(90deg, #ffffff 0%, #ffffff 54%, #d1d7ff 100%);
}
.group_4 {
  flex-shrink: 0;
}
.group_5 {
  width: 4.03rem;
  position: relative;
}
.section_5 {
  margin-right: 0.22rem;
  padding-left: 0.38rem;
  padding-bottom: 1.75rem;
  background-image: url('../../assets/imgs/7419fa75674449dd154996177f6aae4d.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.space-x-15 > *:not(:first-child) {
  margin-left: 0.47rem;
}
.image_5 {
  margin-top: 0.13rem;
  width: 0.44rem;
  height: 0.44rem;
}
.group_6 {
  padding-top: 1.91rem;
  width: 2.5rem;
  position: relative;
}
.image_6 {
  width: 1.25rem;
  height: 1.41rem;
}
.image_7 {
  filter: drop-shadow(0px 0.31rem 0.28rem #3441a31a);
  border-radius: 0.31rem;
  width: 2.19rem;
  height: 2.63rem;
  position: absolute;
  right: 0;
  top: 0;
}
.image_8 {
  width: 0.59rem;
  height: 0.38rem;
  position: absolute;
  right: 0;
  top: 0;
}
.image_9 {
  margin-left: -0.31rem;
  margin-top: 2.97rem;
  width: 0.5rem;
  height: 0.5rem;
}
.group_7 {
  margin: 0.53rem 2.59rem 0 2.28rem;
  height: 3.13rem;
}
.font_2 {
  font-size: 0.75rem;
  font-family: Hiragino Sans GB;
  line-height: 1.31rem;
  color: #a8b3ff;
}
.text_6 {
  font-weight: 700;
}
.group_8 {
  padding-left: 0.91rem;
  padding-right: 0.88rem;
}
.group_9 {
  padding-bottom: 0.56rem;
  border-bottom: solid 0.031rem #eeeeee;
}
.font_3 {
  font-size: 0.94rem;
  font-family: Hiragino Sans GB;
  line-height: 0.88rem;
  font-weight: 700;
  color: #333333;
}
.text_7 {
  align-self: flex-start;
}
.font_4 {
  font-size: 0.88rem;
  font-family: Hiragino Sans GB;
  line-height: 0.88rem;
  color: #999999;
}
.text_8 {
  line-height: 0.84rem;
}
.text_9 {
  color: #8392ff;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.88rem;
}
.group_11 {
  padding-bottom: 0.59rem;
  border-bottom: solid 0.031rem #eeeeee;
}
.view {
  margin-top: 1.22rem;
}
.space-y-23 > *:not(:first-child) {
  margin-top: 0.72rem;
}
.text_10 {
  line-height: 0.91rem;
}
.view_2 {
  margin-top: 1.25rem;
}
.space-y-24 > *:not(:first-child) {
  margin-top: 0.75rem;
}
.text_14 {
  line-height: 0.84rem;
}
.text-wrapper {
  margin-top: 7.25rem;
  padding: 0.78rem 0 0.81rem;
  align-self: center;
  background-color: #8392ff;
  border-radius: 1.25rem;
  width: 12.5rem;
}
.text_15 {
  color: #ffffff;
  line-height: 0.91rem;
}
</style>
<template>
  <div class="flex-col page">
    <div class="flex-col space-y-30 group" style="background-color: #333030;" >
      <div class="flex-row justify-between section" @click="vipdailog=true" v-if="userinfo.isVip == 0">
        <div class="flex-col space-y-12 group_2">
          <div class="flex-row space-x-20">
            <img class="image" src="../../assets/imgs/9c5aae97d0d44e0465d28733ba392f55.png" />
            <img class="image_2" src="../../assets/imgs/a043119cb97ee76c8448659f9570fa84.png" />
          </div>
          <div class="group_3">
            <span class="font_1">开通可享终身</span>
<!--            <span class="font_2 text_2">8</span>-->
            <span class="font_1 text_3">会员优惠</span>
          </div>
        </div>
        <div class="flex-col items-center text-wrapper"><span class="text">立即开通</span></div>
      </div>
      <div class="flex-col space-y-41 section_2">
        <div class="flex-row justify-between group_4">
          <div class="flex-col group_5">
            <div class="flex-row group_6">
              <span class="font_3 text_4">{{userinfo.nickname}}</span>
              <img class="image_4" v-if="userinfo.isVip == 1" src="../../assets/imgs/9c5aae97d0d44e0465d28733ba392f55.png" />
              <div class="flex-col text-wrapper_2"  v-if="userinfo.isVip == 1"><span class="text_5">终身会员</span></div>
              <div class="flex-col text-wrapper_2"  v-if="userinfo.isPhotographer == 1&&userinfo.isContract == 0" ><span class="text_5">注册摄影师</span></div>
              <div class="flex-col text-wrapper_2"  v-if="userinfo.isPhotographer == 1&&userinfo.isContract == 1" ><span class="text_5">签约摄影师</span></div>
            </div>
            <div class="flex-col text-wrapper_2"><span class="text_5">{{userinfo.phone}}</span></div>
          </div>
          <img class="image_3" v-if="!userinfo.avatar"  src="../../assets/imgs/e2c51761fdbb7e19a5bb58aabcd52776.png" />
          <img class="image_3" v-if="userinfo.avatar"  :src="userinfo.avatar" />
        </div>
        <div class="flex-row justify-between" @click="jumptonext(1)">
          <div class="flex-row space-x-28">
            <img class="image_5" src="../../assets/imgs/5eaa934fdc7387c478f3ad614ad70d0e.png" />
            <span class="font_3 text_6">人像下载记录</span>
          </div>
          <div class="flex-row space-x-28 group_7">
            <span class="font_2">{{countdata.portraitCount}}</span>
            <img class="image_6" src="../../assets/imgs/83531ff678e2090c292f48dbcffd7b76.png" />
          </div>
        </div>
        <div class="flex-row justify-between" @click="jumptonext(2)">
          <div class="flex-row space-x-28">
            <img class="image_5" src="../../assets/imgs/1324de0bee8db0617cf626acd6a3b926.png" />
            <span class="font_3 text_7">风景下载记录</span>
          </div>
          <div class="flex-row space-x-28 group_8">
            <span class="font_2">{{countdata.landscapeCount}}</span>
            <img class="image_6" src="../../assets/imgs/83531ff678e2090c292f48dbcffd7b76.png" />
          </div>
        </div>

        <div class="flex-row justify-between" @click="$router.push('/uploadpics')" v-if="userinfo.isPhotographer == 1">
          <div class="flex-row space-x-28">
            <img class="image_5" src="../../assets/imgs/icon_carmera.png" />
            <span class="font_3 text_7">上传图片</span>
          </div>
          <div class="flex-row space-x-28 group_8">

            <img class="image_6" src="../../assets/imgs/83531ff678e2090c292f48dbcffd7b76.png" />
          </div>
        </div>
        <div class="flex-row justify-between" style="margin-top: 20px">
          <van-button round block type="primary"  @click="this.$router.go(-1);">
            返回
          </van-button>
        </div>


<!--        <div class="flex-row justify-between" @click="jumptonext(3)">-->
<!--          <div class="flex-row space-x-29">-->
<!--            <img class="image_5" src="../../assets/imgs/fd8ee33c8da7cb64b6ab9f5aed05d817.png" />-->
<!--            <span class="font_3 text_8">视频下载记录</span>-->
<!--          </div>-->
<!--          <div class="flex-row space-x-28 group_9">-->
<!--            <span class="font_2">{{countdata.videoCount}}</span>-->
<!--            <img class="image_6" src="../../assets/imgs/83531ff678e2090c292f48dbcffd7b76.png" />-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>


    <van-popup v-model:show="vipdailog" style="padding: 1.63rem 0.56rem 1.41rem; width: 100%;
  background-color: #ffffff;text-align: center;
  border-radius: 1.25rem 1.25rem 0px 0px;" @click="this.vipdailog =false" position="bottom">
      <span class="text_4s">开通会员</span>
      <div class="flex-col group_3s space-y-40">
        <div class="flex-row section_6s space-x-25">
          <div class="group_4s">
            <span class="text_5s">{{ vipprice }}</span>
            <span class="text_6s">元</span>
          </div>
          <span class="text_7s">即可成为终身会员</span>
        </div>
        <div class="flex-row space-x-14" style="align-items: center;"  @click.stop="changestatusclick">
          <div :class="changestatus(checkstatus)"></div>
          <div class="group_6s">
            <span class="font_1s text_8s">请仔细阅读开通中天图库</span>
            <span class="font_1s text_9s" @click.stop="jumprules">《会员协议》</span>
          </div>
        </div>
      </div>
      <div class="flex-col items-center buttonss space-y-9" @click.stop="getvip">
        <span class="text_10s">开通终身会员</span>
        <span class="text_11s">会员可享受会员优惠</span>
      </div>
    </van-popup>
  </div>
</template>


<script>
import {getDownLoadStat, getUserInfo, registervip, vipprice} from "@/api/unit";
import {Toast} from "vant";

export default {
  components: {},
  data() {
    return {
      userinfo:{},
      countdata:{
        videoCount:0,
        portraitCount:0,
        landscapeCount:0
      },
      checkstatus: false,
      vipdailog: false,
      vipprice: 0,

    };
  },

  methods: {
    changestatus() {
      if (this.checkstatus) {
        return 'circle'
      } else {
        return 'circle_uneck'
      }
    }, changestatusclick() {
      this.checkstatus = !this.checkstatus;
    }, getstation(){
      var that = this;
      getDownLoadStat().then(function (data) {
        console.log(data);
        if (data.code == 200) {
          if (data.data){
            that.countdata =data.data;
          }
        }
      }).catch(function (err) {
        console.log(err);
      })
    },
    jumprules(){
      this.$router.push('/viprules');
    },
    getvip(){
      if(!this.checkstatus){
        Toast('请阅读并同意相关协议');
        return;
      }
      var that = this;
      registervip().then(function (data) {
        if (data.code == 200) {
          var orderdata =data.data;

          WeixinJSBridge.invoke(
              'getBrandWCPayRequest', {
                "appId":that.$store.state.APPID,     //公众号ID，由商户传入
                "timeStamp": orderdata.timeStamp,         //时间戳，自1970年以来的秒数
                "nonceStr":orderdata.nonceStr, //随机串
                "package":orderdata.packageValue,
                "signType":orderdata.signType,         //微信签名方式：
                "paySign":orderdata.paySign //微信签名
              },
              function(res){
                if(res.err_msg == "get_brand_wcpay_request:ok" ){
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                  alert('购买成功')
                  that.vipdailog=false;
                  that.getuserinfo();
                }
              });

        } else {
          Toast(data.msg)

        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    getuserinfo(){
      var that = this;
      getUserInfo().then(function (data) {
        if (data.code == 200) {
          that.userinfo = data.data;

          that.$store.commit("setuserinfo", data.data);
        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    jumptonext(type){
      this.$router.push('/userdownlist?type='+type);

    }


  },
  mounted() {
var that =this;
    this.userinfo = this.$store.state.userinfo
    console.log(this.userinfo)
      this.getuserinfo();
this.getstation();
    // console.log(this.userinfo);

    vipprice().then(function (data) {
      if (data.code == 200) {
        that.vipprice = data.data / 100;

      }
    }).catch(function (err) {
      console.log(err);
    })
  }
};
</script>

<style scoped lang="css">
.page {

  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.space-y-30 > *:not(:first-child) {
  margin-top: 0.94rem;
}
.group {
  padding-top: 0.84rem;
  overflow-y: auto;
}
.section {
  margin-left: 0.59rem;
  margin-right: 0.66rem;
  padding: 0.56rem 0.69rem 0.63rem;
  border-radius: 0.31rem;
  background-image: url('../../assets/imgs/4152eb9bbf989d2131dff2858d0931da.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.space-y-12 > *:not(:first-child) {
  margin-top: 0.38rem;
}
.group_2 {
  width: 9.81rem;
}
.space-x-20 > *:not(:first-child) {
  margin-left: 0.63rem;
}
.image {
  margin-bottom: 0.094rem;
  width: 1.19rem;
  height: 0.94rem;
}
.image_2 {
  width: 4rem;
  height: 1rem;
}
.group_3 {
  align-self: flex-end;
  line-height: 0.81rem;
}
.font_1 {
  font-size: 0.81rem;
  font-family: MicrosoftYaHei;
  line-height: 0.81rem;
  color: #987861;
}
.font_2 {
  font-size: 1.06rem;
  font-family: Arial;
  line-height: 0.81rem;
  font-weight: 700;
  color: #8392ff;
}
.text_2 {
  color: #ff5046;
}
.text_3 {
  line-height: 0.78rem;
}
.text-wrapper {
  margin: 0.25rem 0.25rem 0.13rem 0;
  padding: 0.47rem 0;
  background-color: #fff2e5;
  background-image: linear-gradient(0deg, #f4dcc4 0%, #ffe3d3 100%);
  border-radius: 0.94rem;
  width: 5rem;
  height: 1.88rem;
}
.text {
  color: #bd6d40;
  font-size: 0.94rem;
  font-family: MicrosoftYaHei;
  line-height: 0.94rem;
}
.space-y-41 > *:not(:first-child) {
  margin-top: 1.28rem;
}
.section_2 {
  padding: 0 0.66rem 14.38rem;
  background-color: #ffffff;
  border-radius: 1.25rem 1.25rem 0px 0px;
}
.group_4 {
  padding: 0.63rem 0;
  border-bottom: solid 0.031rem #eeeeee;
}
.group_5 {
  margin: 0.13rem 0;
}
.group_6 {
  padding-bottom: 0.38rem;
}
.font_3 {
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.81rem;
  color: #333333;
}
.text_4 {
  line-height: 0.84rem;
}
.image_4 {
  margin: 0.063rem 0 0.063rem 0.47rem;
  flex-shrink: 0;
  width: 0.72rem;
  height: 0.72rem;
}
.text-wrapper_2 {
  padding: 0.28rem 0 0.22rem;
  align-self: flex-start;
  background-color: #fff4ec;
  border-radius: 0.5rem;
}
.text_5 {
  margin-left: 0.44rem;
  margin-right: 0.31rem;
  color: #ca997a;
  font-size: 0.69rem;
  font-family: MicrosoftYaHei;
  line-height: 0.53rem;
}
.image_3 {
  margin-right: 0.22rem;
  width: 2.5rem;
  height: 2.5rem;
}
.space-x-28 > *:not(:first-child) {
  margin-left: 0.88rem;
}
.image_5 {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.44rem;
}
.text_6 {
  align-self: center;
  line-height: 0.84rem;
}
.group_7 {
  margin-right: 0.22rem;
  align-self: center;
}
.image_6 {
  flex-shrink: 0;
  width: 0.41rem;
  height: 0.75rem;
}
.text_7 {
  align-self: center;
  line-height: 0.84rem;
}
.group_8 {
  margin-right: 0.22rem;
  align-self: center;
}
.space-x-29 > *:not(:first-child) {
  margin-left: 0.91rem;
}
.text_8 {
  align-self: center;
  line-height: 0.88rem;
}
.group_9 {
  margin-right: 0.22rem;
  align-self: center;
}



.circle {
  background: #8392ff;
  /*border: 0.1em ;*/
  border-radius: 50%;
  /*box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);*/
  height: 1em;
  width: 1em;
}

.circle_uneck {
  /*background: #8392ff;*/
  border: 0.03em solid;
  border-radius: 50%;
  /*box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);*/
  height: 1em;
  width: 1em;
}

.text_4s {
  align-self: center;
  color: #333333;
  font-size: 0.94rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 0.91rem;
}

.group_3s {
  margin-top: 1.41rem;
}

.space-y-40 > *:not(:first-child) {
  margin-top: 1.25rem;
}

.section_6 {
  margin-right: 0.094rem;
  padding: 1.13rem 0.81rem 0.84rem;
  border-radius: 0.31rem;
  background-image: url('../../assets/imgs/2af78a9e7aa7dadc93d9f0cf025bbeb3.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.space-x-25 > *:not(:first-child) {
  margin-left: 0.78rem;
}


.section_6s {
  margin-right: 0.094rem;
  padding: 1.13rem 0.81rem 0.84rem;
  border-radius: 0.31rem;
  background-image: url('../../assets/imgs/2af78a9e7aa7dadc93d9f0cf025bbeb3.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.space-x-25 > *:not(:first-child) {
  margin-left: 0.78rem;
}
.group_4s {
  margin-bottom: 0.063rem;
  height: 1.41rem;
}
.text_5s {
  color: #ff5046;
  font-size: 1.88rem;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.38rem;
}
.text_6s {
  color: #ff5046;
  font-size: 0.75rem;
  font-family: MicrosoftYaHei;
  line-height: 0.66rem;
}
.text_7s {
  color: #3a2f28;
  font-size: 1.5rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 1.47rem;
}
.space-x-14 > *:not(:first-child) {
  margin-left: 0.44rem;
}
.image_4s {
  width: 0.94rem;
  height: 0.94rem;
}
.group_6s {
  margin-bottom: 0.063rem;
  line-height: 0.84rem;
  height: 0.84rem;
}
.font_1s {
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.84rem;
}
.text_8s {
  color: #666666;
}
.text_9s {
  color: #8392ff;
}
.buttonss {
  margin-top: 1.84rem;
  padding: 0.41rem 0 0.28rem;
  align-self: center;
  background-color: #8392ff;
  border-radius: 1.25rem;

}
.space-y-9 > *:not(:first-child) {
  margin-top: 0.28rem;
}
.text_10s {
  color: #ffffff;
  font-size: 0.94rem;
  font-family: Hiragino Sans GB;
  font-weight: 700;
  line-height: 0.91rem;
}
.text_11s {
  color: #fff600;
  font-size: 0.63rem;
  font-family: Hiragino Sans GB;
  line-height: 0.63rem;
}
</style>
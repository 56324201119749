<template>
  <div class="flex-col page">
    <div class="flex-col section_4 space-y-65">
      <img class="image_4" v-if="status==1" src="../../assets/imgs/056f5f1400ebf2fee27c11d0746dca42.png" />
      <img class="image_4" v-if="status==2||status==4" src="../../assets/imgs/icon_pgstatus2.png" />
      <img class="image_4" v-if="status==3" src="../../assets/imgs/icon_pgstatus3.png" />
      <div class="flex-col items-center space-y-29">
        <span class="text_4" v-if="status==1">工作人员正在审核中</span>
        <span class="text_5" v-if="status==1">一般审核72小时内完，成请耐心等待</span>

        <span class="text_4" v-if="status==2">恭喜您成功注册成为摄影师</span>
        <span class="text_4" v-if="status==4">恭喜您成功注册成为签约摄影师</span>
        <span class="text_5" v-if="status==2||status==4">很多赛事活动期待您的加入 </span>
        <span class="text_5" v-if="status==2||status==4">快去报名适合你的吧 </span>
        <div class="group_6" v-if="status==2" @click="jumpmasterpg">
          <span class="font_2 text_7">你可升级成为</span>
          <span class="text_8">“签约摄影师”</span>
        </div>


        <span class="text_4" v-if="status==3" style="color: red">审核未通过</span>
        <span class="text_5" v-if="status==3">如对审核有疑义请致电 </span>

        <div class="group_6" v-if="status==3">
          <span style=" font-weight: 700;line-height: 1.06rem; color: #333333;font-size: 1rem;">400-4567896</span>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      status:1

    };
  },

  methods: {

    jumpmasterpg(){
      this.$router.push('/masterpg');
    },
  },
  mounted() {
    this.status = this.$route.query.status
  }
};
</script>

<style scoped lang="css">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.section_4 {
  padding: 4.59rem 3.38rem 16.28rem 3.44rem;
  background-color: #ffffff;
  overflow-y: auto;
}
.space-y-65 > *:not(:first-child) {
  margin-top: 2.03rem;
}
.image_4 {
  align-self: center;
  width: 6.44rem;
  height: 5.81rem;
}
.space-y-29 > *:not(:first-child) {
  margin-top: 0.91rem;
}
.text_4 {
  color: #333333;
  font-size: 1rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 1.06rem;
}
.text_5 {
  color: #999999;
  font-size: 0.81rem;
  font-family: MicrosoftYaHei;
  line-height: 0.81rem;
}

.font_2 {
  font-size: 0.81rem;
  font-family: MicrosoftYaHei;
  line-height: 1.13rem;
  color: #999999;
}
.group_6 {
  align-self: center;
  line-height: 0.78rem;
  margin-top: 20px;
}
.text_7 {
  line-height: 0.78rem;
}
.text_8 {
  color: #8392ff;
  font-size: 0.81rem;
  font-family: MicrosoftYaHei;
  line-height: 0.78rem;
}
</style>
import axios from 'axios'
// import Vue from 'vue'

import store from '/src/store/index'
import router from '../router';
import {Toast} from "vant";


// 进行一些全局配置
axios.defaults.baseURL = 'https://api.zhongtiantuku.com'
// axios.defaults.baseURL = 'http://localhost:8001/'
// axios.defaults.withCredentials =false;

axios.defaults.timeout = 100000
let count = 0
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // config.headers['Content-Type'] = 'application/json;charset=utf-8';


    var locurl = window.location.href;

     var islogin=config.url.indexOf("wxmplogin");
         // if ( store.state.token ==''&& islogin==-1){
         //
         //     router.push("/login")
         //     return Promise.reject(null)
         // }
         if ( localStorage.getItem('token','') ==''&& islogin==-1){

             router.push("/login")
             return Promise.reject(null)
         }



    // config.headers['satoken'] =store.state.token ;
    config.headers['satoken'] =localStorage.getItem('token','') ;



    count++
    // Vue.showLoading()
    return config
}, function (error) {
    // 对请求错误做些什么
    // Vue.hiddenLoading()
    return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    count--
    if (count === 0) {
        // Vue.hiddenLoading()
    }
    if (response.data.code==401){
        store.commit("settoken","");
        var locurl = window.location.href;
        var islogin=locurl.indexOf('login');
        console.log(islogin+'islogin')
        if ( store.state.token ==''&&islogin ==-1){

            router.push("/login")
            return Promise.reject(null)
        }

    }
    if (response.data.code !=200){
        Toast(response.data.msg);
    }

    return response
}, function (error) {
    // 对响应错误做点什么
    // Vue.hiddenLoading()
    return Promise.reject(error)
})
// 封装自己的get/post方法
export default {
    get: function (path = '', data = {}) {
        return new Promise(function (resolve, reject) {
            axios.get(path, {
                params: data
            })
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    },
    post: function (path = '', data = {}) {
        return new Promise(function (resolve, reject) {
            if (data.pageSize){
                path = path+'?pageSize='+data.pageSize+'&pageNum='+data.pageNum;
            }
            axios.post(path, data)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    reject(error)
                })
        })
    },
    all: function (list) {
        return new Promise(function (resolve, reject) {
            axios.all(list)
                .then(axios.spread(function (...result) {
                    // 两个请求现在都执行完成
                    resolve(result)
                }))
                .catch(function (err) {
                    reject(err)
                })
        })
    }
}


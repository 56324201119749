<template>
  <div class="flex-col page">
    <div class="grid">
      <div class="flex-col grid-item"  v-for="(item, index) in datalist" :key="index">
        <van-image  :src="item.resourceUrl"
                    class="image_9"  @click="jumpdown(item)">
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
        </van-image>
        <span class="text_7">{{item.actName}}</span>
        <div class="flex-row group_6">

          <span class="text_9">下载时间：{{item.updateTime}}</span>
        </div>
        <div class="flex-row space-x-9 group_7" style="margin-bottom: 10px">

        </div>

      </div>


    </div>
  </div>
</template>

<script>
import {gethistoryresourcelist} from "@/api/unit";

export default {
  components: {},
  data() {
    return {
      type:0,
      id:0,
      datalist:[],
    };
  },

  methods: {
    getinitdata(){
      var that  =this;
      var initdata={
        resourceType:this.type,
        historyType:2,
        activityId:this.id
      }
      gethistoryresourcelist(initdata).then(function (data) {
        if (data.code == 200) {
          that.datalist = data.rows;

        }
      }).catch(function (err) {

        console.log(err);
      })
    },
    jumpdown(item){
      // console.log(item)
      this.$router.push("/imgdetail?id=" + item.id);
    }


  },
  mounted() {

    this.type = this.$route.query.type
    this.id = this.$route.query.id;
    this.getinitdata()
  }
};
</script>

<style scoped lang="css">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.header {
  height: 4rem;
}
.section_1 {
  padding: 0.25rem 0.5rem 0.28rem;
  background-color: #ffffff;
}
.space-x-12 > *:not(:first-child) {
  margin-left: 0.38rem;
}
.image {
  flex-shrink: 0;
  width: 2.41rem;
  height: 0.69rem;
}
.text {
  color: #333333;
  font-size: 0.75rem;
  font-family: Hiragino Sans GB;
  line-height: 0.66rem;
}
.space-x-2 > *:not(:first-child) {
  margin-left: 0.063rem;
}
.text_1 {
  margin-top: 0.063rem;
  color: #333333;
  font-size: 0.75rem;
  font-family: Hiragino Sans GB;
  line-height: 0.59rem;
}
.image_1 {
  flex-shrink: 0;
  width: 1.66rem;
  height: 0.69rem;
}
.section_2 {
  padding: 0.91rem 0.59rem 0.81rem;
  background-color: #ffffff;
  position: relative;
}
.image_2 {
  position: absolute;
  left: 0.59rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0.81rem;
  height: 0.78rem;
}
.text_2 {
  color: #111111;
  font-size: 1.06rem;
  font-family: Hiragino Sans GB;
  font-weight: 700;
  line-height: 1.03rem;
}
.image_3 {
  align-self: center;
  position: absolute;
  right: 0.63rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.13rem;
  height: 0.25rem;
}
.group_2 {
  flex: 1 1 auto;
  overflow-y: auto;
}
.section_3 {
  padding: 0.31rem 0;
  background-color: #ffffff;
}
.space-x-15 > *:not(:first-child) {
  margin-left: 0.47rem;
}
.search {
  margin-left: 0.69rem;
  margin-right: 0.63rem;
  padding: 0.63rem 0.69rem 0.59rem;
  background-color: #f4f4f4;
  border-radius: 1.06rem;
}
.image_4 {
  width: 1.19rem;
  height: 0.91rem;
}
.text_3 {
  color: #aaaaaa;
  font-size: 0.88rem;
  font-family: Hiragino Sans GB;
  line-height: 0.84rem;
}
.section_4 {
  padding: 0.52rem 0;
  background-color: #ffffff;
}
.space-x-40 > *:not(:first-child) {
  margin-left: 1.25rem;
}
.equal-division {
  padding-left: 0.63rem;
  padding-right: 0.55rem;
}
.space-x-11 > *:not(:first-child) {
  margin-left: 0.34rem;
}
.equal-division-item {
  padding: 0.31rem 0.31rem;
}
.image_5 {
  flex-shrink: 0;
  width: 0.94rem;
  height: 0.94rem;
}
.text_4 {
  color: #8392ff;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 0.81rem;
}
.text_5 {
  margin: 0.063rem 0 0.063rem 0.34rem;
  color: #333333;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.81rem;
}
.space-x-13 > *:not(:first-child) {
  margin-left: 0.41rem;
}
.text_6 {
  color: #333333;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.81rem;
}
.grid {
  margin: 0.66rem 0 -5rem;
  padding: 0 0.63rem 0.63rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.67rem;
  column-gap: 0.67rem;
}
.grid-item {
  background-color: #ffffff;
  border-radius: 0.25rem;

}
.image_9 {
  border-radius: 0.25rem 0.25rem 0px 0px;

}
.text_7 {
  margin-left: 0.63rem;
  margin-top: 0.59rem;
  align-self: flex-start;
  color: #333333;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 0.91rem;
}
.group_6 {
  margin-top: 0.59rem;
  padding: 0 0.66rem;
}
.image_11 {
  width: 0.63rem;
  height: 0.63rem;
}
.text_9 {
  margin: 0.063rem 0 0.063rem 0.31rem;
  color: #999999;
  font-size: 0.63rem;
  font-family: Hiragino Sans GB;
  line-height: 1rem;
}
.space-x-9 > *:not(:first-child) {
  margin-left: 0.28rem;
}
.group_7 {
  margin-top: 0.31rem;
  padding: 0 0.69rem;
}
.image_13 {
  width: 0.56rem;
  height: 0.63rem;
}
.text_11 {
  color: #999999;
  font-size: 0.69rem;
  font-family: Hiragino Sans GB;
  line-height: 0.66rem;
}
.group_8 {
  margin-top: 0.59rem;
  padding: 0.56rem 0.47rem 0.56rem 0.59rem;
  border-top: solid 0.031rem #eeeeee;
}
.group_9 {
  line-height: 0.75rem;
  height: 0.75rem;
  align-self: center;
  display: block;
}
.text_13 {
  color: #8392ff;
  font-size: 0.94rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 0.75rem;
}
.text_15 {
  color: #8392ff;
  font-size: 0.69rem;
  font-family: MicrosoftYaHei;
  line-height: 0.66rem;
}
.space-x-10 > *:not(:first-child) {
  margin-left: 0.31rem;
}
.group_10 {
  margin-bottom: 0.094rem;
  align-self: center;
  display: block;
}
.image_15 {
  flex-shrink: 0;
  width: 0.66rem;
  height: 0.63rem;
}
.text_17 {
  color: #333333;
  font-size: 0.75rem;
  font-family: MicrosoftYaHei;
  line-height: 0.56rem;
}
</style>
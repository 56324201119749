<template>

</template>

<script>

import {wxmplogin} from "../../api/index"


export default {

  components: {},
  data() {
    return {

      code: "",
    };
  },
  methods: {

    login(codedata) {
      var  that = this;
      console.log(that.$store.state.oldurl)
      wxmplogin(codedata).then(function (data) {
        console.log(data);
        if (data.code == 200) {
          localStorage.setItem('userinfo',data.data.userInfo);
          localStorage.setItem('token',data.data.token);
          that.$store.commit("setuserinfo", data.data.userInfo);
          that.$store.commit("settoken", data.data.token);
          // that.$router.push(that.$store.state.oldurl);
          // that.$router.push(localStorage.getItem('url'));
          // console.log(localStorage.getItem('url'));
          window.location.replace(localStorage.getItem('url'))
        }
      }).catch(function (err) {
        console.log(err);
      })
    }

  },
  mounted() {
    var that = this;
    this.code = this.$route.query.code
    console.log(this.$store.state.istest);
    console.log(this.$store.state.testuid);
    if (this.$store.state.istest) {
      var testcode = {
        "code": 'test_' + this.$store.state.testuid
      }
      that.login(testcode)
    } else {
      if (!this.code) {
        var locurl = window.location.href;
        var url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.$store.state.APPID + '&redirect_uri=' + encodeURIComponent(locurl) + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';
        window.location.replace(url)
      } else {
        var codedata = {
          "code": this.code
        }
        that.login(codedata)
      }
    }

  },setup(){

  }

};

</script>

<style scoped>


</style>
<template>


  <van-form @submit="onSubmit">
    <van-cell-group inset>
      <van-button round block plain type="primary" style="margin-top: 20px;margin-bottom: 20px"  @click="showPicker=true">
        选择活动
      </van-button>
      <van-field
          v-model="dataitem.actName"
          name="活动名称"
          disabled
          label="活动名称"
          placeholder="活动名称"
          :rules="[{ required: true, message: '请选择活动' }]"
      />
      <van-field
          v-model="dataitem.fullcity"
          name="行政区域"
          label="行政区域"
          placeholder="行政区域"
          disabled
          :rules="[{ required: true, message: '请选择活动' }]"
      />
      <van-field
          v-model="dataitem.actDate"
          name="活动时间"
          label="活动时间"
          disabled
          placeholder="活动时间"
          :rules="[{ required: true, message: '请选择活动' }]"
      />

      <van-field
          v-model="dataitem.resourceTypename"
          name="活动类型"
          disabled
          label="活动类型"
          placeholder="活动类型"
          :rules="[{ required: true, message: '请选择活动' }]"
      />



      <van-field name="uploader" label="图片上传">
        <template #input>
          <van-uploader max-count="9" v-model="imglist" multiple :max-size="10000 * 1024" @oversize="onOversize"
                        :after-read="afterRead" />
        </template>
      </van-field>


    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary"   color="linear-gradient(to right, #ff6034, #ee0a24)" native-type="submit">
        提交
      </van-button>
    </div>
    <div style="margin: 16px;">
      <van-button round block type="primary"  @click="this.$router.go(-1);">
        返回
      </van-button>
    </div>
  </van-form>

  <van-popup v-model:show="showPicker" position="bottom">

    <van-picker
        title="选择活动"
        :columns="datalist"
        :columns-field-names="{
      text: 'actName'
    }"
        @confirm="onConfirm"
        @cancel="onCancel"
    />
  </van-popup>


</template>

<script>


import  {getActivityList} from "../../api/index"
import { Dialog } from 'vant';


import {Toast} from "vant";
import axios from "axios";
import {uploadfile,uploadPics} from "@/api/unit";


export default {
  name: "uploadpics",
  data() {
    return {
      datalist :[],
      showPicker:false,
      dataitem:{},
      imglist:[],
      ossdata:null,
      uploadedimg:[],
    }
  },
  mounted() {

    this.getdata();


  },
  methods: {
    afterRead(file){

      // this.fileList.push(file)
    },
    beforeRead(file){

      // console.log(file)
    },

    onOversize(){
      Toast('文件大小不能超过 10M');
    },
    async onSubmit() {
      console.log('提交')
      await this.uploadimgs();

    },
    async uploadimgs() {
      console.log(this.imglist)
      for (let i = 0; i < this.imglist.length; i++) {
        var file = this.imglist[i];
        file.status = 'uploading'
        file.message = '上传中...';
        file.index = i;
        console.log(file)
        var that = this;
        // if (that.ossdata) {
        //   await that.uploadossimg(that.ossdata, file)
        // } else {
        await  uploadfile({type: this.dataitem.resourceType}).then(async function (data) {
            if (data.code == 200) {
              that.ossdata = data.data;
               that.uploadossimg(that.ossdata, file)
            } else {
              Toast(data.msg)
            }
          }).catch(function (err) {
            console.log(err);
          })
        // }

      }




    },
      uploadossimg(ossdata,file){
      var that = this;
      var keyname = ossdata.dir + this.getCurrentTime()+file.file.name;
      let fromData = new FormData();
      fromData.set('key',keyname)
      fromData.set('policy',ossdata.policy)
      fromData.set('OSSAccessKeyId',ossdata.accessid)
      fromData.set('success_action_status',"200")
      fromData.set('signature',ossdata.signature)
      fromData.set('file',file.file)
      fromData.set('expire',ossdata.expire)
      fromData.set('dir',ossdata.dir)
      fromData.set('callback',ossdata.callback)
      axios.post(ossdata.host, fromData,{
        "Content-Type":"multipart/form-data",
        "timeout": 1000 * 60 * 10, }).then((res) => {
        that.imglist[file.index].status = 'done';
        that.imglist[file.index].message = '上传成功';
        console.log(file.index)
        that.uploadedimg.push(keyname.toString());
        if(that.uploadedimg.length ==that.imglist.length){
          that.finupload();
        }
      }).catch(function (err) {
        console.log(err);
        that.imglist[file.index].status='failed';
        that.imglist[file.index].message='上传失败';
      })
    },
    finupload(){

var that = this;
      uploadPics({activityId:this.dataitem.id,pics:this.uploadedimg,resourceType:this.dataitem.resourceType}).then(function (data) {
        if (data.code == 200) {
          Dialog.alert({
            message: '上传成功，请等待审核',
          }).then(() => {
            // on close
            that.imglist=[];
            that.uploadedimg =[]
          });

        } else {
          Toast(data.msg)
        }
      }).catch(function (err) {
        console.log(err);
      })
    },
    getCurrentTime() {
      //获取当前时间并打印
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth()+1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
      _this.localtime = yy+''+mm+''+dd+''+hh+''+mf+''+ss;
      return  _this.localtime;
    },
    getdata(){

      var that = this;

      getActivityList().then(function (data){
        console.log(data);
        if (data.code ==200){
          that.datalist= data.rows;
        }
      }).catch(function (err){
        console.log(err);
      })
    },
    onCancel(value){
      console.log(value)
    },onConfirm(value){
      console.log(value)
      this.dataitem = value;
      this.dataitem.fullcity =value.provinceName + value.cityName + value.districtName;
      if (value.resourceType ==1 ){this.dataitem.resourceTypename ="人像" }
      if (value.resourceType ==2 ){this.dataitem.resourceTypename ="风景" }
      this.showPicker =false;
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="flex-col page">

    <div class="header"><!----></div>
    <div class="flex-col section_3">
      <div class="flex-row justify-between">
        <div class="flex-col items-center space-y-8">
          <span class="text_3">欢迎来到中天图库~</span>
          <span class="text_4">注册成为中天图库摄影师</span>
        </div>
        <div class="flex-col items-center text-wrapper"><span class="text_5">1/2</span></div>
      </div>


      <van-form @submit="onSubmit" ref="formData" class="flex-col space-y-30 group_4">
        <van-cell-group inset>
          <van-field
              style="padding-left: 0"
              v-model="data.realname"
              name="realname"
              placeholder="请输入真实姓名"
              :rules="[{ required: true, message: '请填写用户名' }]"
          />


<!--          <van-field-->
<!--              style="padding-left: 0;"-->
<!--              v-model="data.identityId"-->
<!--              center-->
<!--              placeholder="请输入身份证号"-->
<!--              :rules="[{ required: true, message: '请输入身份证号' }]">-->

<!--          </van-field>-->
          <van-field
              style="padding-left: 0;"
              v-model="data.tempaddress"
              center
              right-icon="arrow"
              placeholder="请选择地区"
              @click="showArea = true"
              :rules="[{ required: true, message: '请选择地区' }]">

          </van-field>
          <van-field
              style="padding-left: 0;"
              v-model="data.address"
              center
              placeholder="请输入详细地址"
              :rules="[{ required: true, message: '请输入详细地址' }]">

          </van-field>

          <van-field
              style="padding-left: 0;"
              v-model="data.imgcode"
              center
              clearable
              placeholder="请输入图形码"
              :rules="[{ required: true, message: '请输入图形码' }]">
            <template #button style="width: 50%">
              <img @click="getcheckcode" :src="checkcoddata.img"  style="width: 100%;"/>
            </template>
          </van-field>

          <van-field
              style="padding-left: 0;"
              v-model="data.phone"
              center
              clearable
              placeholder="请输入手机号码"
              :rules="[{ required: true, message: '请输入手机号码' }]">
            <template #button>
              <span @click="getphonecode" class="text_8">{{content}}</span>
            </template>
          </van-field>

          <van-field
              style="padding-left: 0;"
              v-model="data.vcode"
              center
              placeholder="请输入短信验证码"
              :rules="[{ required: true, message: '请输入短信验证码' }]">

          </van-field>

        </van-cell-group>
        <div style="margin: 16px;">
          <div class="flex-col items-center button" type="primary" native-type="submit" @click="clicknext"><span
              class="text_13">下一步</span>
          </div>
        </div>
      </van-form>


    </div>
  </div>
  <van-popup round v-model:show="showArea" position="bottom">
    <van-area
        :area-list="areadatalist"
        :columns-placeholder="['请选择', '请选择', '请选择']"
        @confirm="onConfirm"
        @cancel="showArea = false"
    />
  </van-popup>
</template>

<script>
import {Toast} from "vant";
import {getRegionData, captchaImage, pgphonecode, bindpgphone, registerpg, getpgregisterstatus,getUserInfo} from "@/api/unit";

export default {
  setup() {
  },
  mounted() {
    this.getregisterstatus();
    this.getregion();
    this.getcheckcode();
    this.getuserinfo();

  },
  components: {},
  data() {
    return {
      data: {
        realname: '',
        phone: '',
        vcode: '',

        tempaddress: '',
        address: '',
        detailaddress: '',
        imgcode: '',
        provinceCode	:'', //省编码
        provinceName:'', //	省名称
        cityName:'', //市编码
        cityCode:'', //市编码
        districtName:'', //区编码
        districtCode:'', //区编码
        isApproval:false,
      },
      areadatalist: {},
      showArea: false,
      checkcoddata: '',
      uuid :'',
      content: '获取验证码',  // 按钮里显示的内容
      totalTime: 60,
      issend:false,
    };
  },
  methods: {
    getuserinfo(){
      var that = this;
      getUserInfo().then(function (data) {
        if (data.code == 200) {
          that.userinfo = data.data;
          that.$store.commit("setuserinfo", data.data);
        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    onConfirm(result) {
      var that = this;
      that.data.address = '';
      console.log(result);
      result.forEach(function (item, index) {
        if (index ==0){that.data.provinceName =item.name; that.data.provinceCode =item.code}
        if (index ==1){that.data.cityName =item.name; that.data.cityCode =item.code}
        if (index ==2){that.data.districtName =item.name; that.data.districtCode =item.code}
        that.data.tempaddress += item.name;
      })
      this.showArea = false;
    },
    clicknext(values) {
      this.$refs.formData.submit();
    },
    onSubmit(values) {
      var that = this;
      if (!this.uuid){
        Toast('请验证手机号码是否正确')
        return;
      }

      var bind = {
        "code": this.data.vcode,
        "phone": this.data.phone,
        "uuid": this.uuid
      }
      bindpgphone(bind).then(function (data) {
        if (data.code == 200) {
that.gotostep2();
        }
      }).catch(function (err) {
        console.log(err);
      })
      //
    },
    gotostep2(){
      var that = this;
      registerpg(that.data).then(function (data) {
        if (data.code == 200) {
          that.$router.push('/register_next');
        } else {


        }
      }).catch(function (err) {
        console.log(err);
      })
    },
    getregisterstatus(){
      var that = this;
      getpgregisterstatus().then(function (data) {
        // console.log(data);
        if (data.code == 200) {
          if (data.data.approvalStatus>0){
            if (data.data.isContract ==1){
              that.$router.push('/register_check?status=4');
            }else {
              that.$router.push('/register_check?status='+data.data.approvalStatus);
            }


            // that.$router.push('/register_check?status='+2);

          }
        }
      }).catch(function (err) {
        console.log(err);
      })
    },
    getregion() {
      //获取地区json
      var that = this;
      getRegionData().then(function (data) {
        console.log(data);
        if (data.code == 200) {
          that.areadatalist = data.data;
        }
      }).catch(function (err) {
        console.log(err);
      })
    },
    getcheckcode() {
      var that = this;
      //获取验证图形
      captchaImage().then(function (data) {
        console.log(data);
        if (data.code == 200) {
          var checkcodeurl =
              "data:image/jpg;base64," + data.data.img.replace(/[\r\n]/g, "");
          that.checkcoddata = data.data;
          that.checkcoddata.img = data.data.img;

        } else {
        }
      }).catch(function (err) {
        console.log(err);
      })
    },
    getphonecode() {
      var that = this;
      var phonecode = {
        "code": "",
        "phone": "",
        "uuid": ""
      }
      if (!this.data.phone) {
        Toast('请填写手机号码');
        return;

      }

      if (!this.data.imgcode) {
        Toast('请填写验证码');
        return;
      }

      if (this.issend){
        return;
      }

        phonecode.phone = this.data.phone;
        phonecode.code = this.data.imgcode;
        phonecode.uuid = this.checkcoddata.uuid;
        pgphonecode(phonecode).then(function (data) {
          console.log(phonecode);
          if (data.code == 200) {
            that.uuid = data.data;
            that.issend =true;
            that.countDown();
          } else {
            Toast(data.msg);
            that.getcheckcode();
          }
        }).catch(function (err) {
          console.log(err);
        })



    },
    countDown () {
      this.content = this.totalTime + 's后重新发送' //这里解决60秒不见了的问题
      let clock = window.setInterval(() => {
        this.totalTime--
        this.content = this.totalTime + 's后重新发送'
        if (this.totalTime < 0) {     //当倒计时小于0时清除定时器
          window.clearInterval(clock)
          this.issend=false;
          this.content = '重新发送验证码'
          this.totalTime = 60
        }
      },1000)
    },

  },
};
</script>

<style scoped lang="css">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

}

.header {
  height: 0rem;
}

.section_3 {
  padding: 1.31rem 0.88rem 3.88rem 0.91rem;
  flex: 1 1 auto;
  background-color: #ffffff;
  overflow-y: auto;
}

.space-y-8 > *:not(:first-child) {
  margin-top: 0.25rem;
}

.text_3 {
  color: #333333;
  font-size: 0.94rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 0.94rem;
}

.text_4 {
  color: #666666;
  font-size: 0.75rem;
  font-family: MicrosoftYaHei;
  line-height: 0.75rem;
}

.text-wrapper {
  margin-right: 0.094rem;
  padding: 0.14rem 0 0.11rem;
  border-radius: 0.44rem;
  width: 1.56rem;
  height: 0.88rem;
  border: solid 0.031rem #999999;
}

.text_5 {
  color: #666666;
  font-size: 0.63rem;
  font-family: MicrosoftYaHei;
  line-height: 0.56rem;
}

.space-y-30 > *:not(:first-child) {
  margin-top: 0.94rem;
}

.group_4 {
  margin-top: 1.88rem;
  padding-bottom: 0.94rem;
  border-bottom: solid 0.031rem #eeeeee;
}

.text_6 {
  align-self: flex-start;
  color: #aaaaaa;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.84rem;
}

.group_5 {
  padding: 0.94rem 0 0.91rem;
  border-top: solid 0.031rem #eeeeee;
  border-bottom: solid 0.031rem #eeeeee;
}

.text_7 {
  color: #aaaaaa;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.84rem;
}

.text_8 {
  color: #8392ff;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.88rem;
}

.text_9 {
  align-self: flex-start;
  color: #aaaaaa;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.88rem;
}

.divider {
  margin-right: 0.094rem;
  background-color: #eeeeee;
  height: 0.031rem;
}

.text_10 {
  align-self: flex-start;
  color: #aaaaaa;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.84rem;
}

.group_6 {
  padding: 0.97rem 0 0.94rem;
  border-top: solid 0.031rem #eeeeee;
  border-bottom: solid 0.031rem #eeeeee;
}

.text_11 {
  color: #aaaaaa;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.81rem;
}

.image_3 {
  margin-right: 0.69rem;
  align-self: center;
  width: 0.47rem;
  height: 0.41rem;
}

.text_12 {
  align-self: flex-start;
  color: #aaaaaa;
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.88rem;
}

.button {
  margin-right: 0.094rem;
  margin-top: 4.16rem;
  padding: 0.91rem 0 0.88rem;
  background-color: #8392ff;
  border-radius: 1.38rem;
}

.text_13 {
  color: #fefefe;
  font-size: 1rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 0.97rem;
}
</style>
<template>
  <div class="flex-col page">
    <div class="flex-col group_2">
      <div class="flex-col list space-y-20">
        <div class="flex-row justify-between list-item" :key="i" v-for="(item, i) in datalist"  v-if="datalist" @click="jumpdetail(item)">
          <div class="flex-row space-x-20">
            <img class="image_3" :src="item.pic" />
            <div class="flex-col items-start space-y-15">
              <span class="font_1">{{item.actName}}</span>
              <div class="group_4">
                <span class="font_2">下载数量：</span>
                <span class="font_3 text_3">{{item.count}}张</span>
              </div>
              <div class="group_5">
                <span class="font_2">下载费用：</span>
                <span class="font_4">￥{{item.countPrice}}</span>
              </div>
            </div>
          </div>
          <div class="flex-col items-center group_6 space-y-48">
            <span class="font_5">{{item.sum}}张</span>
            <span class="font_6">查看详情 &gt;</span>
          </div>
        </div>
        <div   style="align-self: center;margin-top: 20px;color: #999999;"  v-if="datalist.length ==0"> <img style="width: 100%" src="../../assets/imgs/img_null.png"></div>

        <span style="align-self: center;color: #999999;"  v-if="datalist.length ==0">您还未下载任何照片，快去下载吧！</span>
        <van-button round block type="primary"  @click="this.$router.go(-1);" v-if="datalist.length ==0">
          返回
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {gethistorylist} from "@/api/unit";

export default {
  components: {},
  data() {
    return {

      type:0,
      datalist :[]
    };
  },

  methods: {
    getinitdata(){
      var that  =this;
      var initdata={
        resourceType:this.type,
        historyType:2
      }
      gethistorylist(initdata).then(function (data) {
        if (data.code == 200) {

          console.log(data.data);
          that.datalist = data.rows;

          // that.vipprice = data.data / 100;

        }
      }).catch(function (err) {

        console.log(err);
      })
    },jumpdetail(item){
      console.log(item);
      this.$router.push('/dwondetail?type='+this.type+'&id='+item.id);

    },

  },mounted() {
    this.type = this.$route.query.type
    this.getinitdata()
  }
};
</script>

<style scoped lang="css">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.group_2 {
  padding: 0.59rem 0 1.47rem;
  overflow-y: auto;
}
.list {
  margin: 0 0.63rem;
}
.space-y-20 > *:not(:first-child) {
  margin-top: 0.63rem;
}
.list-item {
  padding: 0.63rem 0.34rem 0.63rem 0.63rem;
  background-color: #ffffff;
  border-radius: 0.25rem;
}
.space-x-20 > *:not(:first-child) {
  margin-left: 0.63rem;
}
.image_3 {
  border-radius: 0.31rem;
  width: 3.13rem;
  height: 3.13rem;
}
.space-y-15 > *:not(:first-child) {
  margin-top: 0.47rem;
}
.font_1 {
  font-size: 0.88rem;
  font-family: Hiragino Sans GB;
  line-height: 0.88rem;
  font-weight: 700;
  color: #111111;
}
.group_4 {
  line-height: 0.66rem;
}
.font_2 {
  font-size: 0.69rem;
  font-family: Hiragino Sans GB;
  line-height: 0.66rem;
  color: #999999;
}
.font_3 {
  font-size: 0.69rem;
  font-family: Hiragino Sans GB;
  line-height: 0.66rem;
  color: #333333;
}
.text_3 {
  line-height: 0.63rem;
}
.group_5 {
  line-height: 0.66rem;
}
.font_4 {
  font-size: 0.69rem;
  font-family: Hiragino Sans GB;
  line-height: 0.56rem;
  color: #ff5a00;
}
.group_6 {
  margin-top: 0.094rem;
}
.space-y-48 > *:not(:first-child) {
  margin-top: 1.5rem;
}
.font_5 {
  font-size: 0.88rem;
  font-family: Hiragino Sans GB;
  line-height: 0.81rem;
  font-weight: 700;
  color: #111111;
}
.font_6 {
  font-size: 0.75rem;
  font-family: Hiragino Sans GB;
  line-height: 0.72rem;
  color: #8392ff;
}
</style>
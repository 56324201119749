<template>
  <div className="flex-col page">

    <van-form @submit="onSubmit" ref="formData">
      <van-cell-group >
    <div className="flex-col section_4">
      <div className="flex-row justify-between">

        <span className="text_4">银行信息（必填）</span>
        <div className="flex-col items-center text-wrapper"><span className="text_5">3/4</span></div>
      </div>

      <div className="flex-col group_4 view space-y-30">
        <div className="flex-row justify-between group_4">
          <van-field
              v-model="data.bankBranch"
              name="开户行"
              placeholder="开户行"
              :rules="[{ required: true, message: '请填写开户行' }]"
          />

<!--          <span className="font_2">开户行</span>-->
        </div>
        <van-field
            v-model="data.bankCard"
            name="银行卡号"
            placeholder="银行卡号"
            :rules="[{ required: true, message: '请填写银行卡号' }]"
        />
<!--        <span className="font_2 text_7">银行卡号</span>-->
      </div>
      <div>
      <div className="flex-row group_5 space-x-21">
        <div    :class="changestatus(checkstatus)" @click="changestatusclick"></div>
        <div className="group_6">
          <span className="font_2">我同意</span>
          <span className="font_2 text_9">《中天图库拍摄协议》</span>
        </div>
      </div>
      <div className="flex-col items-center button"  @click="clicknext"><span className="text_10">注册</span></div>
    </div>
    </div>
    </van-cell-group></van-form>

  </div>
</template>

<script>
import {Toast} from "vant";
import {registerpg} from "@/api/unit";

export default {
  components: {},
  data() {
    return {
      data:{
        bankBranch:'',
        bankCard:'',
        isApproval:true,
      },
      checkstatus:false,


    };
  },

  methods: {
    changestatus(){
      if (this.checkstatus){
        return 'circle'
      }else {
        return 'circle_uneck'
      }


    },changestatusclick(){
      this.checkstatus =!this.checkstatus;
    },
    onSubmit(values){
      //提交
      var that = this;
      if(!this.checkstatus){
        Toast('请阅读并同意相关协议');
        return;
      }

      registerpg(that.data).then(function (data) {
        if (data.code == 200) {
          that.$router.push('/register_check?status=1');
        } else {
          Toast(data.msg)

        }
      }).catch(function (err) {
        console.log(err);
      })
    },
    clicknext(){
      this.$refs.formData.submit();


    },

  },
};
</script>

<style scoped lang="css">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section_4 {
  padding: 1.28rem 0.91rem 3.88rem;
  background-color: #ffffff;
  overflow-y: auto;
}

.text_4 {
  color: #333333;
  font-size: 0.94rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 0.97rem;
}

.text-wrapper {
  margin-right: 0.063rem;
  margin-bottom: 0.094rem;
  padding: 0.13rem 0;
  border-radius: 0.44rem;
  width: 1.56rem;
  height: 0.88rem;
  border: solid 0.031rem #999999;
}

.text_5 {
  color: #666666;
  font-size: 0.63rem;
  font-family: MicrosoftYaHei;
  line-height: 0.56rem;
}

.group_4 {
  padding-bottom: 0.94rem;
  border-bottom: solid 0.031rem #eeeeee;
}

.view {
  margin-top: 2.84rem;
}

.space-y-30 > *:not(:first-child) {
  margin-top: 0.94rem;
}

.font_2 {
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.84rem;
  color: #aaaaaa;
}

.image_4 {
  margin-right: 0.66rem;
  margin-top: 0.44rem;
  width: 0.47rem;
  height: 0.41rem;
}

.text_7 {
  align-self: flex-start;
}

.group_5 {
  margin-top: 12.31rem;
}

.space-x-21 > *:not(:first-child) {
  margin-left: 0.66rem;
}

.image_5 {
  width: 0.94rem;
  height: 0.94rem;
}

.group_6 {
  margin-bottom: 0.063rem;
  line-height: 0.84rem;
  height: 0.84rem;
}

.text_9 {
  color: #8392ff;
}

.circle {
  background: #8392ff;
  /*border: 0.1em ;*/
  border-radius: 50%;
  /*box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);*/
  height: 1em;
  width: 1em;
}
.circle_uneck {
  /*background: #8392ff;*/
  border: 0.03em solid;
  border-radius: 50%;
  /*box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);*/
  height: 1em;
  width: 1em;
}

.button {
  margin-right: 0.063rem;
  margin-top: 1.94rem;
  padding: 0.91rem 0 0.84rem;
  background-color: #8392ff;
  border-radius: 1.38rem;
}

.text_10 {
  color: #fefefe;
  font-size: 1rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 1rem;
}
</style>
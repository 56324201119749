<template>
  <div className="flex-col page">

    <div className="flex-col group_3">
      <div className="flex-col group_4">
        <div className="flex-row justify-between section_4" @click="vipdailog =true" v-if="userinfo.isVip == 0">
          <span className="font_2 text_4">开通会员可享受会员优惠</span>
          <span className="font_2 text_5">立即开通</span>
        </div>
        <div className="flex-col group_5">
          <img
              className="image_5"
              :src="imgdetail.resourceUrl"
              v-if="imgdetail.resourceType !=3 "
          />
          <video
              style="display: block;
    width: 100%;
    height: 100%;"
              controls
              :controlslist="videoproject"
              v-if="imgdetail.resourceType ==3 " :controls ="false" :src="imgdetail.resourceUrl">
            您的浏览器不支持video
          </video>
          <span className="font_2 text_6">图片大小：{{ imgdetail.sizeVo }}</span>
          <div className="flex-row group_6">
            <span className="font_2">下载费用：</span>
            <span className="text_8" v-if="userinfo.isVip == 0">{{ normalprice }}元</span>
            <span className="text_9" v-if="userinfo.isVip == 0">VIP：{{ vipprice }}元</span>
            <span className="text_8" v-if="userinfo.isVip == 1">{{ vipprice }}元</span>
<!--            <span className="text_9" v-if="userinfo.isVip == 1">原价{{ normalprice }}</span>-->
          </div>
          <div className="flex-row group_6" v-if="imgdetail.isBuy==1" style="    align-self: center;">

            <span className="text_8" v-if="imgdetail.resourceType !=3"  >请长按图片进行下载</span>
            <span className="text_8" v-if="imgdetail.resourceType ==3" >请点击视频，下载视频</span>

          </div>
        </div>
        <div className="flex-col items-center button" v-if="imgdetail.resourceType !=1||facesearch ==true" @click="wxpay"><span className="text_10">{{ btn_content }}</span>

        </div>
        <van-uploader :after-read="afterRead" capture="camera" style="align-self: center"  v-if="!isCompare && imgdetail.resourceType ==1 && facesearch ==false && imgdetail.isBuy !=1">
        <div className="flex-col items-center button" ><span className="text_10">下载高清图</span>
        </div>
          <span style="font-size: 15px;color: #8392ff;display: flex;justify-content: center;">人脸识别，本人下载</span>
        </van-uploader>
        <div className="flex-col items-center button" v-if="imgdetail.resourceType ==1 && facesearch ==false && imgdetail.isBuy==1"><span className="text_10">已购买成功</span>
        </div>
      </div>
      <div className="section_5">
        <div class=" items-center section_3s" style="display: flex;justify-content: space-around;">
          <div style=" background-color: #8392ff;
          border-radius: 1.25rem; color: #ffffff ; padding: 0.5rem 1rem 0.5rem 1rem "  @click="this.$router.go(-1);">返回</div>
          <div style="width: 70%" >
            <van-search v-model="value"   show-action   background="transparent"   shape="round" placeholder="请输入口令" >

              <template #action>
                <div style=" background-color: #8392ff;
          border-radius: 1.25rem; color: #ffffff ; padding: 0 1rem "  @click="onClickButton">确认</div>
              </template>
            </van-search>
          </div>

        </div>


      </div>
    </div>





    <van-popup v-model:show="rulersdailog" style="padding: 1.63rem 0.56rem 1.41rem; width: 100%;
  background-color: #ffffff;text-align: center;
  border-radius: 1.25rem 1.25rem 0px 0px;" @click="this.rulersdailog =false" position="bottom">
      <span class="text_4s">中天图库用户协议</span>
      <div class="flex-col group_3s space-y-40">

        <div class="flex-row space-x-14"   >

          <div class="group_6s">
            <span class="font_1s text_8s">亲爱的用户，非常感谢您使用中天图库。我们依据相关规章制度拟定了《用户协议》相关条款，请仔细阅读，点击同意继续表示您已阅读并同意该协议所有内容</span>
<!--            <span class="font_1s text_9s" @click.stop="jumprules">《会员协议》</span>-->
          </div>
        </div>
      </div>
      <div style="display: flex;
    justify-content: space-around;width: 100%">
        <div class="flex-col items-center buttonsss space-y-9" style="background-color: white ;border: 1px solid" @click.stop=" this.$router.push('/viprules');">
          <span class="text_10s" style="color: #3a2f28">查看协议</span>
          <!--        <span class="text_11s">会员可享受会员优惠</span>-->
        </div>
        <div class="flex-col items-center buttonsss space-y-9" @click.stop="agreeuserinfo">
          <span class="text_10s">同意继续</span>
          <!--        <span class="text_11s">会员可享受会员优惠</span>-->
        </div>
      </div>

    </van-popup>


    <van-popup v-model:show="vipdailog" style="padding: 1.63rem 0.56rem 1.41rem; width: 100%;
  background-color: #ffffff;text-align: center;
  border-radius: 1.25rem 1.25rem 0px 0px;" @click="this.vipdailog =false" position="bottom">
      <span class="text_4s">开通会员</span>
      <div class="flex-col group_3s space-y-40">
        <div class="flex-row section_6s space-x-25">
          <div class="group_4s">
            <span class="text_5s">{{ vipprice }}</span>
            <span class="text_6s">元</span>
          </div>
          <span class="text_7s">即可成为终身会员</span>
        </div>
        <div class="flex-row space-x-14" style="align-items: center;"  @click.stop="changestatusclick">
          <div :class="changestatus(checkstatus)"></div>
          <div class="group_6s">
            <span class="font_1s text_8s">请仔细阅读开通中天图库</span>
            <span class="font_1s text_9s" @click.stop="jumprules">《会员协议》</span>
          </div>
        </div>
      </div>
      <div class="flex-col items-center buttonss space-y-9" @click.stop="getvip">
        <span class="text_10s">开通终身会员</span>
        <span class="text_11s">会员可享受会员优惠</span>
      </div>
    </van-popup>
  </div>
</template>

<script>

import {getResourceDetail, getDownloadUrl, getactivitybycode} from "../../api/index"
import {
  compareFace,
  createOrder,
  generateUrl,
  getUserInfo,
  registervip,
  seachface,
  updateUserInfo,
  uploadfile
} from "../../api/unit"
import {Toast} from "vant";
import axios from "axios";


export default {
  components: {},
  data() {
    return {
      id: '',
      imgdetail: {
        priceConfig: {
          price: 0,
          vipPrice: 0
        },
        isBuy: 0,
      },
      wxpaydata: {},
      btn_content: '下载高清图',
      userinfo: {},
      rulersdailog:false,
      imgsrc:'',
      uploadedimg:'',
      imglist:[],
      ossimgurl:'',
      isCompare :false,
      facesearch:false,
      vipdailog:false,
      checkstatus: false,
      videoproject:'nodownload nofullscreen noremoteplayback noplaybackrate'


    };
  },
  computed: {
    normalprice() {

      return (this.imgdetail.priceConfig.price) / 100;
    },
    vipprice() {

      return (this.imgdetail.priceConfig.vipPrice) / 100;
    },


  },


  methods: {


    onClickButton(){


      if (!this.value){

        Toast("请输入口令!")
        return;
      };


      var that = this;
      getactivitybycode({
        code:this.value,
        resourceId:this.id
      }).then(function (data) {
        if (data.code == 200) {
          Toast("已获取活动图片")
          that.wxpay();
        }else {
          // Toast("以获取活动图片")
        }
      }).catch(function (err) {
        console.log(err);
      })
    },

    getdata() {
      var that = this;
      getResourceDetail({id: this.id}).then(function (data) {
        console.log(data);
        if (data.code == 200) {
          that.imgdetail = data.data;
          if (that.imgdetail.resourceType ==3 ){
            that.btn_content = '下载视频';
          }
          if (data.data.isBuy == 1) {

            that.btn_content = '已购买成功';
            that.videoproject='';
            that.getmoredata();
          }

        }
      }).catch(function (err) {
        console.log(err);
      })
    },
    getmoredata() {
      var that = this;
      getDownloadUrl({resourceId: this.id}).then(function (data) {
        console.log(data);
        if (data.code == 200) {
          that.imgdetail.resourceUrl = data.data
        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    getvip(){
      if(!this.checkstatus){
        Toast('请阅读并同意相关协议');
        return;
      }
      var that = this;
      registervip().then(function (data) {
        if (data.code == 200) {
          var orderdata =data.data;

          WeixinJSBridge.invoke(
              'getBrandWCPayRequest', {
                "appId":that.$store.state.APPID,     //公众号ID，由商户传入
                "timeStamp": orderdata.timeStamp,         //时间戳，自1970年以来的秒数
                "nonceStr":orderdata.nonceStr, //随机串
                "package":orderdata.packageValue,
                "signType":orderdata.signType,         //微信签名方式：
                "paySign":orderdata.paySign //微信签名
              },
              function(res){
                if(res.err_msg == "get_brand_wcpay_request:ok" ){
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                  alert('购买成功')
                  that.vipdailog=false;
                  that.getuserinfo();
                }
              });

        } else {
          Toast(data.msg)

        }
      }).catch(function (err) {
        console.log(err);
      })

    },


    wxpay() {

      var that = this;


      //0224取消跳转绑定手机
      // if (!this.userinfo.phone) {
      //   this.$router.push('/userphone')
      //   return;
      // }
      console.log(this.userinfo);

      if (this.userinfo.isAgreement == 0) {
        // this.$router.push('/userphone')
        this.rulersdailog=true;
        return;
      }

      createOrder({resourceId: that.id,isCompare:this.isCompare}).then(function (data) {
        console.log(data);
        if (data.code == 200) {
          var orderdata = data.data;

          if (data.data){
            // function onBridgeReady(){
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                  "appId": that.$store.state.APPID,     //公众号ID，由商户传入
                  "timeStamp": orderdata.timeStamp,         //时间戳，自1970年以来的秒数
                  "nonceStr": orderdata.nonceStr, //随机串
                  "package": orderdata.packageValue,
                  "signType": orderdata.signType,         //微信签名方式：
                  "paySign": orderdata.paySign //微信签名
                },
                function (res) {
                  if (res.err_msg == "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    alert('购买成功')
                    that.getdata();
                  }
                });
          }else {
            that.getdata();
          }


        }
      }).catch(function (err) {
        console.log(err);
      })


    },
    agreeuserinfo(){
      var that = this;
      updateUserInfo({ "isAgreement": 1 }).then(function (data) {
        if (data.code == 200) {
          that.getuserinfo();
          that.rulersdailog =false;
        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    getuserinfo(){
      var that = this;
      getUserInfo().then(function (data) {
        if (data.code == 200) {
          that.userinfo = data.data;
          that.$store.commit("setuserinfo", data.data);
        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    changestatus() {
      if (this.checkstatus) {
        return 'circle'
      } else {
        return 'circle_uneck'
      }


    }, changestatusclick() {
      this.checkstatus = !this.checkstatus;
    },
    afterRead(file){
      file.status ='uploading'
      file.message = '上传中...';
      console.log(file)
      this.imgsrc = file.content;
      this.imglist.push(file);
      file.index = this.imglist.length-1;
      var that =this;
      uploadfile().then(function (data) {
        if (data.code == 200) {
          that.uploadossimg(data.data,file)

        } else {
          Toast(data.msg)

        }
      }).catch(function (err) {
        console.log(err);
      })

    }, uploadossimg(ossdata,file){
      var that = this;
      var keyname = ossdata.dir + this.getCurrentTime()+file.file.name;
      let fromData = new FormData();
      fromData.set('key',keyname)
      fromData.set('policy',ossdata.policy)
      fromData.set('OSSAccessKeyId',ossdata.accessid)
      fromData.set('success_action_status',200)
      fromData.set('signature',ossdata.signature)
      fromData.set('file',file.file)
      fromData.set('expire',ossdata.expire)
      fromData.set('dir',ossdata.dir)
      fromData.set('callback',ossdata.callback)
      axios.post(ossdata.host, fromData,{
        "Content-Type":"multipart/form-data",
        "timeout": 1000 * 60 * 10,      }).then((res) => {
        that.imglist[file.index].status='done';
        that.imglist[file.index].message='上传成功';
        console.log(res)
        that.uploadedimg =keyname.toString();
        that.getimgurl();
      }).catch(function (err) {
        console.log(err);
        that.imglist[file.index].status='failed';
        that.imglist[file.index].message='上传失败';

      })


    },
    getimgurl(){
      var that =this;

      if (this.uploadedimg){
        generateUrl({key:this.uploadedimg}).then(function (data) {
          if (data.code == 200) {

            that.seachface(data.data);
          } else {
            Toast(data.msg)

          }
        }).catch(function (err) {
          console.log(err);
        })
      }else {
        Toast('图片上传中，请稍后重试');
      }


    }, seachface(imgurl){
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中..."
      });
      var that = this;
      var updata ={
        imageURLA:imgurl,
        imageURLB:this.imgdetail.resourceUrl
      }
      compareFace(updata).then(function (data) {

        toast.clear();
        if (data.code == 200) {
          that.isCompare = data.data;
          if (data.data) {
            that.wxpay();

          } else {
            Toast('人脸识别失败');
          }

        } else {
          Toast(data.msg)

        }
      }).catch(function (err) {

        toast.clear();
        console.log(err);
      })

    },

    getCurrentTime() {
      //获取当前时间并打印
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth()+1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
      _this.localtime = yy+''+mm+''+dd+''+hh+''+mf+''+ss;
      return  _this.localtime;

    },

  },

  mounted() {
    this.id = this.$route.query.id
    this.facesearch = this.$store.state.iscompare;
    this.getdata();
    this.getuserinfo();
    this.userinfo = this.$store.state.userinfo


  }
};
</script>

<style scoped lang="css">
.font_1 {
  font-size: 0.75rem;
  font-family: Hiragino Sans GB;
  color: #333333;
}

.font_2 {
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.84rem;
  color: #333333;
}

.page {
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.header {
  height: 4rem;
}

.group_3 {
  flex: 1 1 auto;
  overflow-y: auto;
}

.section_2 {
  padding: 0.25rem 0.5rem 0.28rem;
  background-color: #ffffff;
}

.section_3 {
  padding: 0.91rem 0.59rem 0.81rem;
  background-color: #ffffff;
  position: relative;
}
.section_3s {

  padding: 0.31rem 0;
  background-color: #00000080;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

}

.group_4 {
  padding: 0.56rem 0.59rem 0.88rem;
}

.section_5 {
  background-color: #ffffff;
  height: 3.06rem;
}

.image_3 {
  position: absolute;
  left: 0.59rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0.81rem;
  height: 0.78rem;
}

.text_3 {
  color: #111111;
  font-size: 1.06rem;
  font-family: Hiragino Sans GB;
  font-weight: 700;
  line-height: 1.03rem;
}

.image_4 {
  align-self: center;
  position: absolute;
  right: 0.63rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.13rem;
  height: 0.25rem;
}

.section_4 {
  margin-right: 0.063rem;
  padding: 1.16rem 0.75rem 1.06rem;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/636f1c33fe65f70012e99a12/16690190943631841165.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_5 {
  margin-top: 2.59rem;
}

.button {
  margin-top: 4.28rem;
  padding: 0.78rem 0 0.81rem;
  align-self: center;
  background-color: #8392ff;
  border-radius: 1.25rem;
  width: 15.63rem;
}

.image {
  flex-shrink: 0;
  width: 2.41rem;
  height: 0.69rem;
}

.text {
  line-height: 0.66rem;
}

.text_2 {
  margin-top: 0.063rem;
  line-height: 0.59rem;
}

.image_2 {
  flex-shrink: 0;
  width: 1.66rem;
  height: 0.69rem;
}

.text_4 {
  margin-top: 0.063rem;
}

.text_5 {
  margin-right: 0.56rem;
  font-size: 0.91rem;
  font-weight: 700;
  line-height: 0.88rem;
}

.image_5 {
  width: 100%;
  height: 200px;
  object-fit: contain;
}


.text_6 {
  margin-top: 0.91rem;
  align-self: flex-start;
}

.group_6 {
  margin-top: 0.63rem;
}

.text_10 {
  color: #ffffff;
  font-size: 0.94rem;
  font-family: Hiragino Sans GB;
  font-weight: 700;
  line-height: 0.91rem;
}

.text_8 {
  margin-left: 0.19rem;
  color: #ff5a00;
  font-size: 1.06rem;
  font-family: MicrosoftYaHei;
  line-height: 0.81rem;
}

.text_9 {
  margin-left: 0.19rem;
  color: red;
  font-size: 1.06rem;
  font-family: MicrosoftYaHei;
  line-height: 0.81rem;
}

.text_9 {
  margin-left: 0.41rem;
  margin-top: 0.22rem;
  color: #999999;
  font-size: 0.69rem;
  font-family: MicrosoftYaHei;
  line-height: 0.66rem;
  text-decoration: line-through;
}

.space-x-12 > *:not(:first-child) {
  margin-left: 0.38rem;
}

.space-x-2 > *:not(:first-child) {
  margin-left: 0.063rem;
}



.circle {
  background: #8392ff;
  /*border: 0.1em ;*/
  border-radius: 50%;
  /*box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);*/
  height: 1em;
  width: 1em;
}

.circle_uneck {
  /*background: #8392ff;*/
  border: 0.03em solid;
  border-radius: 50%;
  /*box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);*/
  height: 1em;
  width: 1em;
}

.text_4s {
  align-self: center;
  color: #333333;
  font-size: 0.94rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 0.91rem;
}

.group_3s {
  margin-top: 1.41rem;
}

.space-y-40 > *:not(:first-child) {
  margin-top: 1.25rem;
}

.section_6 {
  margin-right: 0.094rem;
  padding: 1.13rem 0.81rem 0.84rem;
  border-radius: 0.31rem;
  background-image: url('../../assets/imgs/2af78a9e7aa7dadc93d9f0cf025bbeb3.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.space-x-25 > *:not(:first-child) {
  margin-left: 0.78rem;
}


.section_6s {
  margin-right: 0.094rem;
  padding: 1.13rem 0.81rem 0.84rem;
  border-radius: 0.31rem;
  background-image: url('../../assets/imgs/2af78a9e7aa7dadc93d9f0cf025bbeb3.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.space-x-25 > *:not(:first-child) {
  margin-left: 0.78rem;
}
.group_4s {
  margin-bottom: 0.063rem;
  height: 1.41rem;
}
.text_5s {
  color: #ff5046;
  font-size: 1.88rem;
  font-family: Arial;
  font-weight: 700;
  line-height: 1.38rem;
}
.text_6s {
  color: #ff5046;
  font-size: 0.75rem;
  font-family: MicrosoftYaHei;
  line-height: 0.66rem;
}
.text_7s {
  color: #3a2f28;
  font-size: 1.5rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 1.47rem;
}
.space-x-14 > *:not(:first-child) {
  margin-left: 0.44rem;
}
.image_4s {
  width: 0.94rem;
  height: 0.94rem;
}
.group_6s {
  margin-bottom: 0.063rem;
  line-height: 0.84rem;

}
.font_1s {
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.84rem;
}
.text_8s {
  color: #666666;
}
.text_9s {
  color: #8392ff;
}
.buttonss {
  margin-top: 1.84rem;
  padding: 0.58rem 0 0.28rem;
  align-self: center;
  background-color: #8392ff;
  border-radius: 1.25rem;
}


.buttonsss {
  margin-top: 1.84rem;
  padding: 0.58rem 0.58rem 0.28rem 0.58rem;
  align-self: center;
  background-color: #8392ff;
  border-radius: 1.25rem;
}
.space-y-9 > *:not(:first-child) {
  margin-top: 0.28rem;
}
.text_10s {
  color: #ffffff;
  font-size: 0.94rem;
  font-family: Hiragino Sans GB;
  font-weight: 700;
  line-height: 0.91rem;

}
.text_11s {
  color: #fff600;
  font-size: 0.63rem;
  font-family: Hiragino Sans GB;
  line-height: 0.63rem;
}
</style>
// 这个JS文件就是专门用于管理请求各种接口地址的
import Network from './network';

//获取地区列表
export const getRegionData = (data) => Network.get('/api/common/selectRegionDataList',data)

//逆地址解析
export const geocoder = (data) => Network.post('/api/common/geocoder',data)

//获取地区列表
export const gethomeactinfo = (data) => Network.get('/api/h5/cm/homeActInfo',data)

//获取轮播图
export const banner = (data) => Network.get('/api/h5/cm/banner',data)


//获取签名
export const getWxSignature = (data) => Network.get('/api/auth/getWxSignature',data)

//购买图片
export const createOrder = (data) => Network.get('/api/h5/cm/createOrder',data)

//获取图形验证码
export const captchaImage = (data) => Network.get('/api/h5/um/captchaImage',data)

//发送摄影师注册短信验证码
export const pgphonecode = (data) => Network.post('/api/h5/um/sendPhotographerPhoneCode',data)

//发送用户绑定手机短信验证码
export const userphonecode = (data) => Network.post('/api/h5/um/sendUserPhoneCode',data)

//绑定摄影师手机号
export const bindpgphone = (data) => Network.post('/api/h5/um/bindPhotographerPhone',data)
//绑定用户手机号
export const binduserphone = (data) => Network.post('/api/h5/um/bindUserPhone',data)

//绑定用户手机号
export const updateUserInfo = (data) => Network.post('/api/h5/um/updateUserInfo',data)

//注册摄影师
export const registerpg = (data) => Network.post('/api/h5/um/registerPhotographer',data)

//签约摄影价格
export const getContractPrice = (data) => Network.get('/api/common/getContractPrice',data)

//注册签约摄影师
export const registermasterpg = (data) => Network.get('/api/h5/um/contractPhotographer',data)


//vip价格
export const vipprice = (data) => Network.get('/api/common/getVipPrice',data)

//注册vip会员
export const registervip = (data) => Network.get('/api/h5/um/registerVip',data)

//用户下载统计
export const getDownLoadStat = (data) => Network.get('/api/h5/um/getDownLoadStat',data)


//用户历史活动列表
export const gethistorylist = (data) => Network.get('/api/h5/um/historyActList',data)

//用户历史资源列表
export const gethistoryresourcelist = (data) => Network.get('/api/h5/um/historyResourceList',data)


//获取用户信息
export const getUserInfo = (data) => Network.post('/api/h5/um/getUserInfo',data)


//获取字典
export const dicts = (data) => Network.get('/api/common/dicts',data)


//获取注册摄影师审批状态查询
export const getpgregisterstatus = (data) => Network.get('/api/h5/um/getRegisterPhotographerStatus',data)

//上传文件
export const uploadfile = (data) => Network.get('/api/oss/getUploadInfo',data)

//预签名URL
export const generateUrl = (data) => Network.get('/api/oss/generateUrl',data)

//人脸搜索
export const seachface = (data) => Network.post('/api/h5/cm/searchFace',data)

//人脸比对
export const compareFace = (data) => Network.post('/api/h5/cm/compareFace',data)

//上传图片
export const uploadPics = (data) => Network.post('/api/h5/cm/uploadPics',data)







import  Vuex from 'vuex'


export  default  new Vuex.Store({

state:{
    APPID:'wx7d6dabcbdb920529',
    istest:false,
    token:'',
    oldurl:'',
    userinfo:{},
    registerdata:{},
    testuid:2,
    facelist:[],
    locationinfo:'',
    iscompare:false,
},
    getters:{},
    mutations:{
        istest(state,status){
            state.istest =status;
        },
        settoken(state,data){
            state.token =data;
        },
        setoldurl(state,data){
            state.oldurl =data;
        },
        setuserinfo(state,data){
            state.userinfo =data;
        },
        setregisterdata(state,data){
            state.registerdata =data;
        },
        setfacelist(state,data){
            state.facelist =data;
        },
        setlocation(state,data){
            state.locationinfo =data;
        },
        setcompare(state,data){
            state.iscompare =data;
        }
    },


})

<template>
    <div class="flex-col page">
        <div class="flex-col group_2" >
            <div class="flex-col section_3" style="display: flex;flex-direction: row">
<!--              <div class="flex-row space-x-9 group_7" style="align-self: center;width: 24%"  @click="showarea">-->
<!--                <img-->
<!--                    src="../../assets/imgs/icon_location.png"-->

<!--                />-->
<!--                <span class="text_11" style="align-self: center">{{locationname}}</span>-->
<!--              </div>-->
              <form action="/" style="width: 100%">
                <van-search v-model="value"   shape="round" placeholder="请输入活动名称"  @search="onSearch"/>
              </form>
<!--              <div  class="flex-row space-x-9 group_7"  @click="jumpersoncenter">-->
<!--                <img src="../../assets/imgs/img_person_no.png"-->

<!--                     style="align-self: center"-->
<!--                />-->
<!--              </div>-->



            </div>

          <div class="flex-row space-x-40 equal-division" style="background-color: white;display: flex;justify-content: space-evenly;}">
<!--            <div class="flex-row space-x-11 equal-division-item" @click="changephototype(2)">-->
<!--              <img-->
<!--                  :src="[typeindex == 2 ? require('../../assets/imgs/img_scene.png'):require('../../assets/imgs/img_scene_no.png')]"-->
<!--                  class="image_5"-->
<!--              />-->
<!--              <span :class="[typeindex == 2 ? 'text_5_yes':'text_5']">风景照片</span>-->
<!--            </div>-->
<!--            <div class="flex-row equal-division-item" @click="changephototype(1)">-->
<!--              <img-->
<!--                  :src="[typeindex == 1 ? require('../../assets/imgs/img_person.png'):require('../../assets/imgs/img_person_no.png')]"-->
<!--                  class="image_5"-->
<!--              />-->
<!--              <span :class="[typeindex == 1 ? 'text_5_yes':'text_5']">人物照片</span>-->
<!--            </div>-->
<!--            <div class="flex-row space-x-13 equal-division-item" @click="changephototype(3)">-->
<!--              <img-->
<!--                  :src="[typeindex == 3 ? require('../../assets/imgs/img_video.png'):require('../../assets/imgs/img_video_no.png')]"-->
<!--                  class="image_5"-->
<!--              />-->
<!--              <span :class="[typeindex == 3 ? 'text_5_yes':'text_5']">视频</span>-->
<!--            </div>-->
<!--            <div v-for="(item, index) in classtype" :key="index" class="flex-row space-x-13 equal-division-item" @click="changephototype(3)">-->

<!--              <span > {{item.dictLabel}}</span>-->
<!--            </div>-->

            <van-tabs   @click="onClickTab">
              <van-tab v-for="(item, index) in classtype"  :title="item.dictLabel">

              </van-tab>
            </van-tabs>
          </div>

          <van-pull-refresh style="height:100%;width:100%;overflow-y:auto;"  v-model="loading" @refresh="onRefresh" v-if="datalist.length">
            <van-list
                v-model="addloading"
                :finished="finished"
                @load="adddatalist">
          <div class="grid">
                <div class="flex-col grid-item" @click="goto_detail(item)" v-for="(item, index) in datalist" :key="index">
                    <van-image  :src="item.pic"   fit="cover"
                            class="image_9">
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                    <span class="text_7">{{item.actName}}</span>
                    <div class="flex-row group_6">
                        <img  src="../../assets/imgs/icon_time.png"
                                class="image_11"/>
                        <span class="text_9">{{item.actDate}}</span>
                    </div>
                    <div class="flex-row space-x-9 group_7">
                        <img
                                src="../../assets/imgs/icon_location.png"
                                class="image_13"
                        />
                        <span class="text_11">{{item.provinceName}}{{item.cityName}}</span>
                    </div>
                    <div class="flex-row justify-between group_8">
                        <div class="group_9">
                            <span class="text_13">{{item.sum}}</span>
                            <span class="text_15" v-if="paramdata.resourceType==3">个</span>
                          <span class="text_15" v-if="paramdata.resourceType!=3">张</span>
                        </div>
                        <!-- <div class="flex-row space-x-10 group_10">
                            <img
                                    src="../../assets/imgs/icon_down.png"
                                    class="image_15"
                            />
                            <span class="text_17">{{item.count}}</span>
                        </div> -->
                    </div>
                </div>


            </div>
            </van-list>
          </van-pull-refresh>
          <div   style="align-self: center;margin-top: 20px;color: #999999;"  v-if="datalist.length ==0"> <img style="width: 100%" src="../../assets/imgs/img_null.png"></div>
          <span style="align-self: center;color: #999999;"  v-if="datalist.length ==0">该区域暂无数据，请切换地区看看吧！</span>
          <!-- <van-button round block v-if="datalist.length ==0" type="primary"  @click="this.$router.go(-1);">
            返回
          </van-button> -->
        </div>
        <div class="but1">
          <van-button round block type="primary"  @click="this.$router.go(-1);">
            返回
          </van-button>
        </div>
      <van-popup round v-model:show="showArea" position="bottom">
        <van-area
            :area-list="areadatalist"
            :columns-placeholder="['请选择', '请选择', '请选择']"
            @confirm="onConfirm"
            @cancel="showArea = false"
        />
      </van-popup>
    </div>
</template>

<script>
import  {getActivityList} from "../../api/index"

import { ref } from 'vue';
import { Toast } from 'vant';
import {dicts, getRegionData} from "@/api/unit";

    export default {
setup(){
var that = this;
},
      components: {},
        data() {
            return {
              typeindex: 2,
              datalist :[],
              value:"",
              classtype:[],
              loading:false,
              addloading:false,
              finished:true,
              paramdata:{
                pageNum:1,
                pageSize:20,
                actName:'',
                resourceType:2,
                provinceCode:'',
                cityCode:'',
                districtCode:'',
                resourceTypeSub:''
              },
              areadatalist:{},
              showArea:false,
              locationname:'全国',
            };
        },
        methods: {
          onClickTab(item){

            if (item==0){
              console.log(this.classtype[item])
              this.paramdata.resourceTypeSub='';

            }else {
              console.log(this.classtype[item])
              this.paramdata.resourceTypeSub= this.classtype[item].dictSort;
            }
            this.onSearch();



          },

            goto_detail(item){
              console.log(item);
              this.$router.push("/photodetail?id="+item.id+'&type='+this.typeindex);
            },
          imgError(item){
            // console.log( axios.defaults.baseURL+item.pic);
            // console.log(item.pic);
            // item.img = require('../../assets/img-default.png');
            item.pic =  axios.defaults.baseURL+item.pic;
          },
          onConfirm(result){
            console.log(result);


            var that = this;
            result.forEach(function (item, index) {
              console.log(item);
              if (index ==0){
                if (item){
                  if (item.code){
                    that.locationname =item.name; that.paramdata.provinceCode =item.code
                  }

                }

              }
              if (index ==1){
                if (item){
                  if (item.code){
                    that.locationname =item.name; that.paramdata.cityCode =item.code
                  }

                }
              }
              if (index ==2){

                if (item){
                  if (item.code){
                    that.locationname =item.name; that.paramdata.districtCode =item.code
                  }

                }

              }

            })
            that.paramdata.pageNum=1
            that.showArea =false;
            that.onloading();

          },
          showarea(){
              if (!this.showArea){
                this.getregion();
              }else {
                this.showArea =true;
              }
          },
          getregion() {
            //获取地区json
            var that = this;
            getRegionData().then(function (data) {
              console.log(data);
              if (data.code == 200) {
                that.areadatalist = data.data;
                that.showArea =true;
              }
            }).catch(function (err) {
              console.log(err);
            })
          },
          changephototype(index) {
            this.typeindex = index;
            this.paramdata.pageNum=1;
            this.paramdata.resourceType=index;
            this.onloading();
            },
          jumpersoncenter(){

            this.$router.push("/personcenter");

          },
          onSearch(){
            var that = this;
            this.paramdata.actName =this.value;
            this.paramdata.pageNum=1;
            this.finished = true;
            getActivityList(this.paramdata
            ).then(function (data){
              console.log(data);
              if (data.code ==200){
                that.datalist= data.rows;
              }
            }).catch(function (err){
              console.log(err);
            })
          },
          onloading(){
            var that = this;
            that.finished=true;
            getActivityList(this.paramdata
            ).then(function (data){
              console.log(data);
              if (data.code ==200){
                that.datalist= data.rows;
                if (that.datalist.length >= data.total){
                  that.finished=true;
                }else {
                  that.finished=false;
                }
              }
            }).catch(function (err){
              console.log(err);
            })
          },
          adddatalist(){
              this.addloading = true;
              this.paramdata.pageNum++;
            var that = this;
            that.finished=true;
            getActivityList(this.paramdata
            ).then(function (data){
              if (data.code ==200){
                that.datalist.push.apply(that.datalist,data.rows);
              if (that.datalist.length >= data.total){
                that.finished=true;
              }
              }
              that.addloading =false;
            }).catch(function (err){
              console.log(err);
            })
          },
          onRefresh(){
              this.paramdata.pageNum=1;
              this.onloading();
            setTimeout(() => {
              Toast('刷新成功');
              this.loading = false;
            }, 1000);
          },

        },
      mounted() {
        var that = this;
        if (localStorage.getItem('locationdata','')){
          var locationitem = JSON.parse(localStorage.getItem('locationdata',''));
          this.paramdata.districtCode =locationitem.id;
          this.locationname =locationitem.name;
        }
         this.typeindex = this.$route.query.type
        this.paramdata.resourceType = this.$route.query.type;
        this.onloading();

        dicts({key:'resource_type_sub'}).then(function (data) {
          if (data.code == 200) {
            var list =[]

            list=data.data;

            list.unshift({dictCode:0,dictLabel:'全部'})
            that.classtype =list;

          }else {
            Toast(data.msg)
          }
        }).catch(function (err) {
          console.log(err);
        })
      }



    };
</script>

<style scoped lang="css">
    .page {
        background-color: #f4f4f4;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
    }
    .header {
        height: 4rem;
    }
    .section_1 {
        padding: 0.25rem 0.5rem 0.28rem;
        background-color: #ffffff;
    }
    .space-x-12 > *:not(:first-child) {
        margin-left: 0.38rem;
    }
    .image {
        flex-shrink: 0;
        width: 2.41rem;
        height: 0.69rem;
    }
    .text {
        color: #333333;
        font-size: 0.75rem;
        font-family: Hiragino Sans GB;
        line-height: 0.66rem;
    }
    .space-x-2 > *:not(:first-child) {
        margin-left: 0.063rem;
    }
    .text_1 {
        margin-top: 0.063rem;
        color: #333333;
        font-size: 0.75rem;
        font-family: Hiragino Sans GB;
        line-height: 0.59rem;
    }
    .image_1 {
        flex-shrink: 0;
        width: 1.66rem;
        height: 0.69rem;
    }
    .section_2 {
        padding: 0.91rem 0.59rem 0.81rem;
        background-color: #ffffff;
        position: relative;
    }
    .image_2 {
        position: absolute;
        left: 0.59rem;
        top: 50%;
        transform: translateY(-50%);
        width: 0.81rem;
        height: 0.78rem;
    }
    .text_2 {
        color: #111111;
        font-size: 1.06rem;
        font-family: Hiragino Sans GB;
        font-weight: 700;
        line-height: 1.03rem;
    }
    .image_3 {
        align-self: center;
        position: absolute;
        right: 0.63rem;
        top: 50%;
        transform: translateY(-50%);
        width: 1.13rem;
        height: 0.25rem;
    }
    .group_2 {
        flex: 1 1 auto;
        overflow-y: auto;
    }
    .section_3 {
        padding: 0.31rem 0;
        background-color: #ffffff;
    }
    .space-x-15 > *:not(:first-child) {
        margin-left: 0.47rem;
    }
    .search {
        margin-left: 0.69rem;
        margin-right: 0.63rem;
        padding: 0.63rem 0.69rem 0.59rem;
        background-color: #f4f4f4;
        border-radius: 1.06rem;
    }
    .image_4 {
        width: 1.19rem;
        height: 0.91rem;
    }
    .text_3 {
        color: #aaaaaa;
        font-size: 0.88rem;
        font-family: Hiragino Sans GB;
        line-height: 0.84rem;
    }
    .section_4 {
        padding: 0.52rem 0;
        background-color: #ffffff;
    }
    .space-x-40 > *:not(:first-child) {
        margin-left: 1.25rem;
    }
    .equal-division {
        padding-left: 0.63rem;
        padding-right: 0.55rem;
    }
    .space-x-11 > *:not(:first-child) {
        margin-left: 0.34rem;
    }
    .equal-division-item {
        padding: 0.31rem 0.31rem;
    }
    .image_5 {
        flex-shrink: 0;
        width: 0.94rem;
        height: 0.94rem;
    }
    .text_4 {
        color: #8392ff;
        font-size: 0.88rem;
        font-family: MicrosoftYaHei;
        font-weight: 700;
        line-height: 0.81rem;
    }
    .text_5 {
        margin: 0.063rem 0 0.063rem 0.34rem;
        color: #333333;
        font-size: 0.88rem;
        font-family: MicrosoftYaHei;
        line-height: 0.81rem;
    }
    .space-x-13 > *:not(:first-child) {
        margin-left: 0.41rem;
    }
    .text_6 {
        color: #333333;
        font-size: 0.88rem;
        font-family: MicrosoftYaHei;
        line-height: 0.81rem;
    }
    .grid {
        margin: 0.66rem 0 -5rem;
        padding: 0 0.63rem 0.63rem;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 0.67rem;
        column-gap: 0.67rem;
    }
    .grid-item {
        background-color: #ffffff;
        border-radius: 0.25rem;
    }
    .image_9 {
        border-radius: 0.25rem 0.25rem 0px 0px;
        height: 6.63rem;
    }
    .text_7 {
        margin-left: 0.63rem;
        margin-top: 0.59rem;
        align-self: flex-start;
        color: #333333;
        font-size: 0.88rem;
        font-family: MicrosoftYaHei;
        font-weight: 700;
        line-height: 0.91rem;
    }
    .group_6 {
        margin-top: 0.59rem;
        padding: 0 0.66rem;
    }
    .image_11 {
        width: 0.63rem;
        height: 0.63rem;
    }
    .text_9 {
        margin: 0.063rem 0 0.063rem 0.31rem;
        color: #999999;
        font-size: 0.63rem;
        font-family: Hiragino Sans GB;
        line-height: 0.5rem;
    }
    .space-x-9 > *:not(:first-child) {
        margin-left: 0.28rem;
    }
    .group_7 {
        margin-top: 0.31rem;
        padding: 0 0.69rem;
    }
    .image_13 {
        width: 0.56rem;
        height: 0.63rem;
    }
    .text_11 {
        color: #999999;
        font-size: 0.69rem;
        font-family: Hiragino Sans GB;
        line-height: 0.66rem;
    }
    .group_8 {
        margin-top: 0.59rem;
        padding: 0.56rem 0.47rem 0.56rem 0.59rem;
        border-top: solid 0.031rem #eeeeee;
    }
    .group_9 {
        line-height: 0.75rem;
        height: 0.75rem;
      align-self: center;
      display: block;
    }
    .text_13 {
        color: #8392ff;
        font-size: 0.94rem;
        font-family: MicrosoftYaHei;
        font-weight: 700;
        line-height: 0.75rem;
    }
    .text_15 {
        color: #8392ff;
        font-size: 0.69rem;
        font-family: MicrosoftYaHei;
        line-height: 0.66rem;
    }
    .space-x-10 > *:not(:first-child) {
        margin-left: 0.31rem;
    }
    .group_10 {
        margin-bottom: 0.094rem;
      align-self: center;
      display: block;
    }
    .image_15 {
        flex-shrink: 0;
        width: 0.66rem;
        height: 0.63rem;
    }
    .text_17 {
        color: #333333;
        font-size: 0.75rem;
        font-family: MicrosoftYaHei;
        line-height: 0.56rem;
    }

    .text_5_yes {
      margin: 0.063rem 0 0.063rem 0.34rem;
      color: #8392ff;
      font-size: 0.88rem;
      font-family: MicrosoftYaHei;
      line-height: 0.81rem;
    }
    .but1{
      height: 2.3rem;
      margin-bottom: 0.5rem;
    }
</style>
<template>
  <div class="flex-col page">
    <div class="flex-col section_4">
      <div class="flex-row justify-between">
        <div class="flex-row space-x-19">
          <div class="section_5"></div>
          <span class="font_2">个人信息（必填）</span>
        </div>
        <div class="flex-col items-center text-wrapper"><span class="text_5">2/2</span></div>
      </div>
      <van-form @submit="onSubmit" ref="formData">
        <van-cell-group >
      <div class="flex-col group_5 view_2">


        <van-field
            v-model="data.signature "
            name="照片署名"
            label="照片署名"
            placeholder="照片署名"
            :rules="[{ required: true, message: '请填写照片署名' }]"
        />

        <van-field
            v-model="data.birthday"
            name="出生日期"
            label="出生日期"
            is-link
            readonly
            placeholder="出生日期"
            @click="showPicker = true"
            :rules="[{ required: true, message: '请填写出生日期' }]"
        />

        <van-popup v-model:show="showPicker" position="bottom">
          <van-datetime-picker

              :min-date="minDate"
              :max-date="maxDate"
              type="date"
              @confirm="onConfirm"
              @cancel="showPicker = false"
          />
        </van-popup>

                  <van-field
                      style="margin-left: 0"
                      v-model="data.identityId"
                      name="身份证号"
                      label="身份证号"
                      center
                      placeholder="请输入身份证号"
                      :rules="[{ required: true, message: '请输入身份证号' }]">

                  </van-field>

      </div>
      <div class="flex-col group_9 space-y-29">
        <div class="flex-row justify-between">
          <div class="flex-row space-x-19">
            <div class="section_5"></div>
            <span class="font_2">摄影师类别（必填）</span>
          </div>
          <span class="font_5 text_13">摄影师类别说明</span>
        </div>
        <div class="flex-row group_12 space-x-19">
          <div class="flex-col items-center text-wrapper_2" v-for="(item, index) in pglevel" :key="index"  :class="changebtn(item.dictValue)" @click="changepglevel(item.dictValue)"><span class="font_6 text_14">{{item.dictLabel}}</span></div>

          </div>
      </div>

          <van-field

              v-model="data.photographYear"
              name="摄影年限"
              center
              placeholder="请输入摄影年限"
              :rules="[{ required: true, message: '摄影年限' }]">

          </van-field>

      <div class="flex-col group_5 view_4">

        <div class="flex-row justify-between group_14">
          <span class="font_4 text_18">荣誉资质（选填）</span>
          <div class="flex-col items-center text-wrapper_4">
            <van-uploader :after-read="afterRead" >
              <span class="font_6 text_19" style="display: block">点击上传</span>
            </van-uploader>

            </div>
        </div>
        <div>

          <van-uploader  v-model="imglist">
            <span ></span>
          </van-uploader>

        </div>
      </div>

          <div className="flex-row " style="margin-top: 16px">
            <div    :class="changestatus(checkstatus)" @click="changestatusclick"></div>
            <div className="group_6sp">
              <span className="font_2sp">我同意</span>
              <span className="font_2sp text_9sp" @click=" this.$router.push('/rules');">《中天图库拍摄协议》</span>
            </div>
          </div>

      <div class="flex-col items-center text-wrapper_5"  type="primary" native-type="submit" @click="clicknext"><span class="text_20">下一步</span></div>
        </van-cell-group>
      </van-form>
    </div>
  </div>
</template>



<script>
import {registerpg,dicts,uploadfile} from "@/api/unit";
import {Toast} from "vant";
import  axios from 'axios';

export default {
  components: {},
  data() {
    return {
      data:{signature :'',
        birthday:'',
        isApproval:false,
        identityId:'',
        photographYear:'',
        photographerType:'',
        honorCertified:'',
      },
      showPicker:false,
      pglevel:[],
      minDate: new Date(1940, 0, 1),
      maxDate: new Date(2025, 10, 1),
      imglist:[
      ],
      uploadedimg:[],
      localtime:'',
      checkstatus:false,
    };
  },

  methods:{
      changestatus(){
  if (this.checkstatus){
    return 'circle'
  }else {
    return 'circle_uneck'
  }


},changestatusclick(){
      this.checkstatus =!this.checkstatus;
    },
    afterRead(file){
      file.status ='uploading'
      file.message = '上传中...';
      console.log(file)
      this.imglist.push(file);
      file.index = this.imglist.length-1;
         var that =this;
      uploadfile().then(function (data) {
        if (data.code == 200) {
          that.uploadossimg(data.data,file)
        } else {
          Toast(data.msg)
        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    uploadossimg(ossdata,file){
      var that = this;
      var keyname = ossdata.dir + this.getCurrentTime()+file.file.name;
      let fromData = new FormData();
      fromData.set('key',keyname)
      fromData.set('policy',ossdata.policy)
      fromData.set('OSSAccessKeyId',ossdata.accessid)
      fromData.set('success_action_status',"200")
      fromData.set('signature',ossdata.signature)
      fromData.set('file',file.file)
      fromData.set('expire',ossdata.expire)
      fromData.set('dir',ossdata.dir)
      fromData.set('callback',ossdata.callback)
      axios.post(ossdata.host, fromData,{
        "Content-Type":"multipart/form-data",
        "timeout": 1000 * 60 * 10,      }).then((res) => {
        that.imglist[file.index].status='done';
        that.imglist[file.index].message='上传成功';
            // console.log(res.data)
        that.uploadedimg.push(keyname.toString());
          }).catch(function (err) {
        console.log(err);
        that.imglist[file.index].status='failed';
        that.imglist[file.index].message='上传失败';
      })
    },
    getCurrentTime() {
      //获取当前时间并打印
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth()+1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
      _this.localtime = yy+''+mm+''+dd+''+hh+''+mf+''+ss;
      return  _this.localtime;
    },
    changebtn(level){
      if (level ==this.data.photographerType){
        return 'checkedbtn';
      }else {
        return ''
      }


    },
    onConfirm(value){
      this.data.birthday = this.formatter(value);
      this.showPicker=false;
      // console.log(this.formatter(value));

    },
    formatter(time) {
      let nowdata =time == null ?  new Date() : new Date(time);
      let year = nowdata.getFullYear();
      let month = nowdata.getMonth() + 1;
      let date = nowdata.getDate();
      let nowtime = year + "-";
      if (month < 10) {
        nowtime += "0";
      }
      nowtime += month + "-";
      if (date < 10) {
        nowtime += "0";
      }
      nowtime += date;

      return nowtime;
    },
    changepglevel(pglevel){
      this.data.photographerType =pglevel;

    },

    onSubmit(values){
      //提交
      var that = this;
      if(!this.data.photographerType){
        Toast('请选择摄影师类别');
        return;
      }

      if(!this.checkstatus){
        Toast('请阅读并同意相关协议');
        return;
      }
      that.data.honorCertified =that.uploadedimg.toString();
      registerpg(that.data).then(function (data) {
        if (data.code == 200) {
          // that.$router.push('/register_bank');
          that.finsh();
        } else {
          Toast(data.msg)

        }
      }).catch(function (err) {
        console.log(err);
      })
    },
    finsh(){
      //提交
      var that = this;
      registerpg(that.data).then(function (data) {
        if (data.code == 200) {
          that.$router.push('/register_check?status=1');
        } else {
          Toast(data.msg)

        }
      }).catch(function (err) {
        console.log(err);
      })
    },
    clicknext(){
      this.$refs.formData.submit();


    },
  },mounted() {
    var that = this;
    dicts({key:'photographer_type'}).then(function (data) {
      if (data.code == 200) {
        that.pglevel=data.data;

      }else {
        Toast(data.msg)
      }
    }).catch(function (err) {
      console.log(err);
    })
  }
};
</script>

<style scoped lang="css">
.page {
  padding-top: 0.063rem;
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.circle {
  background: #8392ff;
  /*border: 0.1em ;*/
  border-radius: 50%;
  /*box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);*/
  height: 1em;
  width: 1em;
}
.circle_uneck {
  /*background: #8392ff;*/
  border: 0.03em solid;
  border-radius: 50%;
  /*box-shadow: 0.375em 0.375em 0 0 rgba(15, 28, 63, 0.125);*/
  height: 1em;
  width: 1em;
}
.section_4 {
  padding: 1.28rem 0.84rem 4.44rem 0.91rem;
  background-color: #ffffff;
}
.section_5 {
  margin-bottom: 0.094rem;
  flex-shrink: 0;
  background-color: #8392ff;
  border-radius: 0.094rem;
  width: 0.19rem;
  height: 0.88rem;
}

.font_2sp {
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.84rem;
  color: #aaaaaa;
}

.text_9sp {
  color: #8392ff;
}

.font_2 {
  font-size: 0.94rem;
  font-family: MicrosoftYaHei;
  line-height: 0.97rem;
  font-weight: 700;
  color: #333333;
}
.text-wrapper {
  margin-right: 0.13rem;
  margin-bottom: 0.094rem;
  padding: 0.13rem 0;
  border-radius: 0.44rem;
  width: 1.56rem;
  height: 0.88rem;
  border: solid 0.031rem #999999;
}
.text_5 {
  color: #666666;
  font-size: 0.63rem;
  font-family: MicrosoftYaHei;
  line-height: 0.56rem;
}
.group_5 {
  border-bottom: solid 0.031rem #eeeeee;
}
.view_2 {
  margin-top: 0.66rem;
}
.group_6 {
  padding: 0.94rem 0 0.97rem;
  border-bottom: solid 0.031rem #eeeeee;
}

.group_6sp {
  margin-bottom: 0.063rem;
  line-height: 0.84rem;
  height: 0.84rem;
}
.font_3 {
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.81rem;
  color: #333333;
}
.font_4 {
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.81rem;
  color: #aaaaaa;
}
.group_7 {
  padding: 0.94rem 0.094rem;
  border-bottom: solid 0.031rem #eeeeee;
}
.text_8 {
  line-height: 0.84rem;
}
.text_9 {
  line-height: 0.84rem;
}
.group_8 {
  padding: 0.94rem 0 0.97rem;
}
.space-x-34 > *:not(:first-child) {
  margin-left: 1.06rem;
}
.group_9 {
  margin-top: 1.56rem;
}
.space-y-29 > *:not(:first-child) {
  margin-top: 0.91rem;
}
.font_5 {
  font-size: 0.75rem;
  font-family: MicrosoftYaHei;
  line-height: 0.75rem;
}
.text_13 {
  margin: 0.13rem 0 0.094rem;
  color: #8392ff;
}
.group_12 {
  padding: 0 0.81rem;
}
.space-x-19 > *:not(:first-child) {
  margin-left: 0.59rem;
}
.text-wrapper_2 {
  padding: 0.56rem 0;
  border-radius: 0.94rem;
  width: 5.63rem;
  height: 1.88rem;
  border: solid 0.031rem #333333;
}
.font_6 {
  font-size: 0.75rem;
  font-family: MicrosoftYaHei;
  line-height: 0.72rem;
}
.text_14 {
  color: #333333;
}
.text-wrapper_3 {
  padding: 0.56rem 0 0.5rem;
  border-radius: 0.94rem;
  width: 5.63rem;
  height: 1.88rem;
  border: solid 0.031rem #333333;
}
.text_15 {
  color: #333333;
}
.view_4 {
  margin-top: 1.25rem;
}
.group_13 {
  padding: 0 0.094rem 0.94rem;
  border-bottom: solid 0.031rem #eeeeee;
}
.text_16 {
  line-height: 0.84rem;
}
.group_14 {
  padding: 0.63rem 0 0.72rem;
}
.text_18 {
  margin-top: 0.75rem;
  line-height: 0.91rem;
}
.text-wrapper_4 {
  padding: 0.59rem 0 0.56rem;
  background-color: #eeeeee;
  border-radius: 0.94rem;
  width: 4.41rem;
  height: 1.88rem;
  justify-content: center;
}
.text_19 {
  color: #666666;
}
.text-wrapper_5 {
  margin-right: 0.13rem;
  margin-top: 1.56rem;
  padding: 0.91rem 0 0.88rem;
  background-color: #8392ff;
  border-radius: 1.38rem;
}
.text_20 {
  color: #fefefe;
  font-size: 1rem;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  line-height: 0.97rem;
}
.checkedbtn{
  background-color:#8392ff ;
}
.checkedbtn span{
 color:white;
}
.uncheckedbtn{
  background-color:#ffffff ;
}
</style>
// import {createApp} from 'vue';

import {createRouter, createWebHistory} from 'vue-router';
import store from '@/store/index'
import {get} from '@/api/unit'

import mainindex from '../pages/main_index/main_index.vue';
import index from '../pages/new_index/new_index.vue';
import photodetail from '../pages/photography_detail/detail_index.vue';
import facetime from '../pages/facetime/Facetime_index.vue';
import facedetail from '../pages/facetime/Facetime_detail.vue';
import Register_index from '../pages/register_index/Register_Index.vue';
import Register_next from '../pages/register_step2/step2.vue';
import Register_bank from '../pages/register_step3/step3.vue';
import viprules from '../pages/viprules/viprules.vue';
import rules from '../pages/viprules/rules.vue';
import register_check from '../pages/register_check/register_check.vue';
import masterpg from '../pages/masterpg/masterpg.vue';
import personcenter from '../pages/personcenter/personcenter.vue';
import login from '../pages/login/login.vue';
import imgdetail from '../pages/imgdetail/imgdetail.vue';

import userdownlist from '../pages/UserDownlist/userdownlist.vue';
import dwondetail from '../pages/dwondetail/downdetail.vue';
import userphone from '../pages/userbindphone/userbindphone.vue';
import testpage from '../pages/test/test.vue';
import uploadpics from '../pages/uploadpics/uploadpics.vue';
//
// createApp(App).use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'mainindex',
        component: index,
        meta: {requiresAuth: true}
    },
    {
        path: '/index',
        name: '/index',
        component: mainindex,
        meta: {requiresAuth: true}
    },
    {
        path: '/photodetail',
        name: 'photodetail',
        component: photodetail,
        meta: {requiresAuth: true}
    }, {
        path: '/facetime',
        name: 'facetime',
        component: facetime,
        meta: {requiresAuth: true}
    },
    {
        path: '/facedetail',
        name: 'facedetail',
        component: facedetail,
        meta: {requiresAuth: true}
    },
    {
        path: '/register',
        name: 'Register',
        component: Register_index,
        meta: {requiresAuth: true}
    },
    {
        path: '/imgdetail',
        name: 'imgdetail',
        component: imgdetail,
        meta: {requiresAuth: true}
    }, {
        path: '/personcenter',
        name: 'personcenter',
        component: personcenter,
        meta: {requiresAuth: true}
    }, {
        path: '/login',
        name: 'login',
        component: login,
        meta: {requiresAuth: true}
    },
    {
        path: '/register_next',
        name: 'register_next',
        component: Register_next,
        meta: {requiresAuth: true}
    },
    {
        path: '/register_bank',
        name: 'register_bank',
        component: Register_bank,
        meta: {requiresAuth: true}
    },
    {
        path: '/register_check',
        name: 'register_check',
        component: register_check,
        meta: {requiresAuth: true}
    },
    {
        path: '/masterpg',
        name: 'masterpg',
        component: masterpg,
        meta: {requiresAuth: true}
    }, {
        path: '/viprules',
        name: 'viprules',
        component: viprules,
        meta: {requiresAuth: true}
    },{
        path: '/userdownlist',
        name: 'userdownlist',
        component: userdownlist,
        meta: {requiresAuth: true}
    },{
        path: '/dwondetail',
        name: 'dwondetail',
        component: dwondetail,
        meta: {requiresAuth: true}
    },{
        path: '/userphone',
        name: 'userphone',
        component: userphone,
        meta: {requiresAuth: true}
    },
    {
        path: '/testpage',
        name: 'testpage',
        component: testpage,
        meta: {requiresAuth: true}
    }, {
        path: '/rules',
        name: 'rules',
        component: rules,
        meta: {requiresAuth: true}
    }
    , {
        path: '/uploadpics',
        name: 'uploadpics',
        component: uploadpics,
        meta: {requiresAuth: true}
    }
];

const router = new createRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {


    if (to.path === '/login') {

        if (window.location.href.indexOf('login')<0){
            let arraypath  =window.location.href.split("/");
            console.log('/'+arraypath[arraypath.length-1])
            store.commit('setoldurl','/'+arraypath[arraypath.length-1])
            // alert(arraypath[arraypath.length-1]);
            // localStorage.setItem('url','/'+arraypath[arraypath.length-1]);
            localStorage.setItem('url',window.location.href);
        }

        next();
    } else {
        // let token = store.state.token;
        let token = localStorage.getItem('token');

        if (token === 'null' || token === '') {
            next('/login');
        } else {
            next();
        }
    }
});


export default router;
import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index'
import axios from 'axios';
import Vant from 'vant';
import { ImagePreview } from 'vant';
import { Lazyload } from 'vant';
import {Toast} from "vant";
// import VConsole from 'vconsole'


import 'vant/lib/index.css';

const app = createApp(App)
// const vConsole = new VConsole()
app.use(store)
app.use(Lazyload)
app.use(router)
app.use(Vant)
app.use(ImagePreview)
// app.use(vConsole)
app.config.globalProperties.$axios = axios

app.mount('#app')
// new {createApp}({
//     router,
//     render: (h) => h(App),
// }).$mount('#app');


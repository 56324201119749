<template>
  <div class="flex-col justify-start page">
    <div class="flex-col group_2 space-y-20">
      <div class="flex-col justify-start section_3">
        <div class="flex-row justify-between search">
          <div class="flex-row group_3 space-x-14" @click="showarea">
            <img class="image_7" src="../../assets/imgs/92a41f2b0a62719b8d6ea2ff92c107a2.png" />
            <span class="text_4">{{ locationdata.name }}</span>
          </div>
          <img  class="image_6" src="../../assets/imgs/d1f313bfc705b2e75dc9d810a8725474.png" @click=" this.$router.push('/personcenter')" />
        </div>
      </div>
      <div class="flex-col group_4 space-y-20" style=" border-radius:0.31rem;  transform: translateY(0); " >
        <div style="height: 35%">
        <van-swipe :autoplay="3000" style="height: 100%" lazy-render>
          <van-swipe-item v-for="(item, i)  in bannerlist" :key="i" @click="jumpto(item)">
            <img  class="image_8" style="object-fit: cover;height: 100%" :src="item.pic" />
          </van-swipe-item>
        </van-swipe>
        </div>

        <div class="flex-col space-y-20">
          <div class="flex-row justify-between section_4" :key="i" v-for="(item, i) in list_uUfBdi49" @click=" this.$router.push('/index?type='+item.type)">
            <div class="flex-row space-x-48" >
              <van-swipe  :autoplay="2900" duration="4000" lazy-render class="image_9" :circular="true" :show-indicators="false" v-if="item.type == 2">
                <van-swipe-item v-for="(item, i)  in viewlist" :key="i">
                  <img  class="image_9" :src="item.pic" />
                </van-swipe-item>
              </van-swipe>
              <van-swipe  :autoplay="2900" duration="4000" lazy-render class="image_9" :show-indicators="false" v-if="item.type == 1">
                <van-swipe-item v-for="(item, i)  in peoplelist" :key="i">
                  <img  class="image_9" :src="item.pic" />
                </van-swipe-item>
              </van-swipe>
              <van-swipe :autoplay="2900" duration="4000"  lazy-render class="image_9" :show-indicators="false" v-if="item.type == 3">
                <van-swipe-item v-for="(item, i)  in videolist" :key="i">
                  <img  class="image_9" :src="item.pic" />
                </van-swipe-item>
              </van-swipe>
<!--              <img class="image_9"  :src="item.imgurl"/>-->
              <div class="flex-col items-start space-y-18" style="align-items: center;">
                <span class="font_2">{{item.title}}</span>
                <span class="font_4"  v-if="item.type == 3">{{item.num}}个视频可下载</span>
                <span class="font_4"  v-if="item.type != 3">{{item.num}}张可下载</span>
                <span class="font_3 text_5">查看 &gt;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-popup round v-model:show="showArea" position="bottom">
      <van-area
          :area-list="areadatalist"
          :columns-placeholder="['请选择', '请选择', '请选择']"
          @confirm="onConfirm"
          @cancel="showArea = false"
      />
    </van-popup>
  </div>
</template>

<script>
import {banner, gethomeactinfo, getWxSignature, geocoder, getUserInfo, getRegionData} from "@/api/unit";

import wx from "weixin-js-sdk";

export default {
  components: {},
  data() {
    return {
      list_uUfBdi49: [
        {
          title:'人物照片',
          imgurl:   require('../../assets/imgs/7a61a2c69c40e68221b8aef546879074.png'),
          num:2000,
          type:1,
        },
          {
        title:'风景照片',
        imgurl: require('../../assets/imgs/078d434dbf5b40cf1fede12c740864df.png'),
        num:1000,
        type:2,
      } ],
    // {
    //   title:'视频',
    //       imgurl: require('../../assets/imgs/51ab0fd312e2855979dbecb8cd603730.png'),
    //     num:3000,
    //     type:3,
    // },
      bannerlist:[],
      peoplelist:[],
      viewlist:[],
      videolist:[],
      locationdata:{name:'',id:''},
      areadatalist:{},
      showArea:false,
    };
  },

  methods: {
    jumpto(item){
      // console.log()
      window.location.href =item.url;

      // window.location.replace(item.url)
      // this.$router.push(item.url);
    },
    getbanner() {
      var that = this;
      banner({type:2}).then(function (data) {
        console.log(data);
        if (data.code == 200) {
          that.bannerlist = data.data;
        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    getpeopleimge() {
      var that = this;
      banner({type:3}).then(function (data) {
        console.log(data);
        if (data.code == 200) {
          that.peoplelist = data.data;
        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    getviewimge() {
      var that = this;
      banner({type:4}).then(function (data) {
        console.log(data);
        if (data.code == 200) {
          that.viewlist = data.data;
        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    getvideoimge() {
      var that = this;
      banner({type:5}).then(function (data) {
        console.log(data);
        if (data.code == 200) {
          that.videolist = data.data;
        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    gethomeinfo() {
      var that = this;
      gethomeactinfo({districtCode:this.locationdata.id}).then(function (data) {
        if (data.code == 200) {
          for (let i = 0; i < that.list_uUfBdi49.length; i++) {
            for (let j = 0; j < data.data.length; j++) {
              if (that.list_uUfBdi49[i].type == data.data[j].typeId) {
                that.list_uUfBdi49[i].num = data.data[j].count;
              }
            }
          }
        }
      }).catch(function (err) {
        console.log(err);
      })

    },
    showarea(){
      if (!this.showArea){
        this.getregion();
      }else {
        this.showArea =true;
      }

    },
    getregion() {
      //获取地区json
      var that = this;
      getRegionData().then(function (data) {
        console.log(data);
        if (data.code == 200) {
          that.areadatalist = data.data;
          that.showArea =true;
        }
      }).catch(function (err) {
        console.log(err);
      })
    },
    onConfirm(result){
      console.log(result);


      var that = this;
      result.forEach(function (item, index) {
        console.log(item);
        if (index ==0){
          if (item){
            if (item.code){
              that.locationdata.name =item.name; that.locationdata.id =item.code
            }
          }

        }
        if (index ==1){
          if (item){
            if (item.code){
              that.locationdata.name =item.name; that.locationdata.id =item.code
            }
          }
        }
        if (index ==2){

          if (item){
            if (item.code){
              that.locationdata.name =item.name; that.locationdata.id =item.code
            }
          }
        }
      })

      // that.$store.commit('setlocation',that.locationdata);
      localStorage.setItem('locationdata',JSON.stringify(that.locationdata));

      that.gethomeinfo();
      that.showArea =false;


    },
    getlocation(url){
      var that = this;
      // if (!sessionStorage.getItem('isfirst')){
      //   sessionStorage.setItem('isfirst',true)
      //   return;
      // }
      if (!url) url = window.location.href.split('#')[0]
      console.log('url',url)
      getWxSignature({url:url}).then(function (data){
        console.log(data);
        if (data.code ==200){
          wx.config({
            debug: false, // 开启调试模式,
            appId: data.data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
            signature: data.data.signature, // 必填，签名，见附录1
            jsApiList: ['getLocation'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          wx.ready(() => {
            wx.checkJsApi({
              jsApiList: ['getLocation'],
              success(res) {
                if (res.checkResult.getLocation === false) {
                  const message = '你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！'
                  reject(message);
                } else {
                  const message = 'js-sdk检查成功';
                  console.log(message);

                  wx.getLocation({
                    type: 'wgs84', // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
                    success: function (res) {
                      console.log('getLocation success',res)
                      var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                      var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                      var coderdata ={
                        lat:res.latitude,
                        lng:res.longitude
                      }
                      that.getgeolacation(coderdata)
                      var speed = res.speed; // 速度，以米/每秒计
                      var accuracy = res.accuracy; // 位置精度
                    }
                  });
                }
              },
              fail(res) {
                const message = 'checkJsApi fail=' + JSON.stringify(res);
                console.log(message);
                reject(message);
              }
            });


          });
          wx.error(function(res){
            console.log("wx.error",res)
            // that.getlocation(res.realAuthUrl[0])
          });


        }
      }).catch(function (err){
        console.log(err);
      })
    },
    getgeolacation(data ){
      var that = this;
      geocoder(data).then(function (data) {
        console.log(data);
        if (data.code == 200) {
          that.locationdata = data.data;
          that.gethomeinfo()
          // that.$store.commit('setlocation',data.data);
          localStorage.setItem('locationdata',JSON.stringify(that.locationdata));

        }
      }).catch(function (err) {
        console.log(err);
      })


    },
    getuserinfo(){
      var that = this;
      getUserInfo().then(function (data) {
        if (data.code == 200) {
          that.userinfo = data.data;
          that.$store.commit("setuserinfo", data.data);
        }
      }).catch(function (err) {
        console.log(err);
      })
    },

  },
  mounted() {


    var templocationdata = '';
    if (localStorage.getItem('locationdata','')){

      templocationdata= JSON.parse(localStorage.getItem('locationdata',''));
      console.log(templocationdata)
    }
    if ( !templocationdata ){
      this.getlocation();
    }else {
      this.locationdata  = templocationdata
    }

    this.getbanner();
    this.getviewimge();
    this.getpeopleimge();
    this.getvideoimge();
    this.gethomeinfo();

      this.getuserinfo();







  }
};
</script>

<style scoped lang="css">
.page {
  background-color: #f4f4f4;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.group_2 {
  padding-bottom: 10.19rem;
  overflow-y: auto;
}
.section_3 {
  padding: 0.69rem 0 0.81rem;
  background-color: #ffffff;
}
.search {
  margin-left: 0.69rem;
  margin-right: 0.94rem;
}
.group_3 {
  align-self: center;
}
.space-x-14 > *:not(:first-child) {
  margin-left: 0.44rem;
}
.image_7 {
  flex-shrink: 0;
  width: 0.69rem;
  height: 0.78rem;
}
.text_4 {
  color: #333333;
  font-size: 0.81rem;
  font-family: Hiragino Sans GB;
  line-height: 0.78rem;
}
.image_6 {
  width: 1.25rem;
  height: 1.25rem;
}
.group_4 {
  padding: 0 0.63rem;
}
.space-y-20 > *:not(:first-child) {
  margin-top: 0.63rem;
}

.image_8 {
  border-radius: 0.41rem;
  width: 100%;
  height: 100%;
  object-fit: fill;
  overflow:hidden ;
  transform: translateY(0);
}
.section_4 {
  padding: 1.06rem 0.56rem 0.44rem;
  background-color: #ffffff;
  /*border-radius: 0.31rem;*/
}
.space-x-48 > *:not(:first-child) {
  margin-left: 1.5rem;
  height: 100%;
  display: grid;
}
.image_9 {
  margin-top: 0.063rem;
  width: 11.81rem;
  border-radius: 0.5rem;
  height: 9.71rem;
  overflow:hidden ;
  transform: translateY(0);

}
.font_2 {
  font-size: 0.89rem;
  font-family: MicrosoftYaHei;
  /*line-height: 0.94rem;*/
  font-weight: 700;
  color: #333333;
}
.font_4 {
  font-size: 0.81rem;
  font-family: MicrosoftYaHei;
  line-height: 0.78rem;
  color: #333333;
}
.font_3 {
  font-size: 0.88rem;
  font-family: MicrosoftYaHei;
  line-height: 0.78rem;
  color: #8694ff;
}
.text_5 {
  line-height: 0.81rem;
}
.text_6 {
  margin-right: 0.22rem;
  margin-top: 0.72rem;
}
.space-y-18 > *:not(:first-child) {
  margin-top: 1.56rem;
}
</style>
